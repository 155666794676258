import React from "react";
import Header from "../../components/Header/Header";
import style from "./Dashboard.module.css"
type Props = {};

const Dashboard = (props: Props) => {
  return (
    <>
      <Header title="ML Dashboard" />
      <h1 className={style.heading}>Coming soon!!!!</h1>
    </>
  );
};
export default Dashboard;
