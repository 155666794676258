import React from 'react'

type Props = {}

const NotFound = (props: Props) => {
  return (
    <div>Page Not Found 404</div>
  )
}

export default NotFound