import { endpoints } from "../utils/config";
import { ResponseHandler } from "../utils/ResponseHandler";
import { LogoutRequestBody, ApiLogoutResponse } from "../types/Logout";

//list parts

// create part
export async function Logout(
  requestBody: LogoutRequestBody
): Promise<boolean | ApiLogoutResponse> {
  const url = endpoints.logout;
  const headers = {
    "Content-Type": `application/json`,
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    "x-timezone-region": Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  const requestOptions = {
    method: `POST`,
    headers: headers,
    body: JSON.stringify(requestBody),
  };
  const response = await fetch(url, requestOptions);
  if (response.status === 205) {
    return true;
  } else {
    const body = await response.json();
    ResponseHandler(response, body);
    return false;
  }
}
