import { hookstate } from "@hookstate/core";
import { PartsRequestBody, Parts, ListData } from "../types/Parts";

export const PartsCreateState = hookstate<PartsRequestBody>({
  number: "",
  name: "",
  userId: "",
  drawingId: "",
});

export const PartsResponseState = hookstate<Parts>({
  number: "",
  name: "",
  userId: "",
  drawingId: "",
  id: "",

  drawingName: "",
  userName: "",
  createdAt: "",
});

export const PartsResponseListState = hookstate<ListData>({
  count: 0,
  next: null,
  previous: null,
  currentPage: 0,
  totalPages: 0,
  results: [],
});
