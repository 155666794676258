import React from "react";
import style from "../../styles/PasswordInput.module.css";
import eyeOpen from "../../assets/eyeopen.svg";
import eyeClose from "../../assets/eye.svg";
import { ChangeEvent } from "react";

export interface IPasswordInputProps {
  content?: string;
  type?: string;
  placeholder?: string;
  value?: any;
  name: string;
  eyeClose: any;
  setEyeClose: any;

  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  clickHandler?: any;
}

export default function PasswordInput(props: IPasswordInputProps) {
  return (
    <>
      <div className={style.formInput}>
        <label className={style.formLabel}>{props.content}</label>
        <br />
        <div className={style.passwordInput}>
          <input
            type={props.type}
            className={style.inputField}
            placeholder={props.placeholder}
            value={props.value}
            name={props.name}
            onChange={props.onChange}
          />
          <button
            type="button"
            className={style.toggleButton}
            onClick={() => props.setEyeClose(!props.eyeClose)}
          >
            {props.eyeClose ? (
              <img src={eyeOpen} alt="" className={style.eyeIcon} />
            ) : (
              <img src={eyeClose} alt="" className={style.eyeIcon} />
            )}
          </button>
        </div>
      </div>
    </>
  );
}
