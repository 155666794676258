import { hookstate } from "@hookstate/core";
import { ProjectRequestBody, Project, ListData } from "../types/Projects";

export const ProjectCreateState = hookstate<ProjectRequestBody>({
  number: "",
  name: "",
  userId: "",
});

const initailState = {
  number: "",
  name: "",
  userId: "",
  id: "",
  totalDrawings: 0,
  userName: "",
  createdAt: "",
};

export const ProjectResponseState = hookstate<Project>(initailState);

export const ProjectResponseListState = hookstate<ListData>({
  count: 0,
  next: null,
  previous: null,
  currentPage: 0,
  totalPages: 0,
  results: [initailState],
});
