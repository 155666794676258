import { hookstate } from "@hookstate/core";
import {
  DataCollectionRequestBody,
  DataCollectionResponse,
  ListData,
} from "../types/DataCollection";

export const DataCollectionCreateState = hookstate<DataCollectionRequestBody>({
  file: "",
  userId: "",
  typeOfWeld: "",
  typeOfDefect: "",
});

const initailState = {
  userName: "",
  userId: "",
  imageUrl: "",
  typeOfWeld: "",
  typeOfDefect: "",
  createdAt: "",
  id: "",
  status: "",
};
export const DataCollectionResponseState =
  hookstate<DataCollectionResponse>(initailState);

// const ListData = hookstate<Array<DataCollectionResponse>>([DataCollectionResponseState])

export const DataCollectionResponseListState = hookstate<ListData>({
  count: 0,
  next: null,
  previous: null,
  currentPage: 0,
  totalPages: 0,
  results: [initailState],
});
