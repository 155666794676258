import * as React from 'react';
import style from '../../styles/Button.module.css'

export interface IButtonProps {
    text: string,
    style?: string,
    // clickHandler:(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    clickHandler?: any
}

const defaultProps = {
  text: `Button`,
  style: style.generalButton
}

export default function Button (props: IButtonProps) {

  return (
   <>
   <button className={props.style} onClick={props.clickHandler}>
    {props.text}
   </button>
   </>
  );
}


Button.defaultProps = defaultProps; 