import React, { ChangeEvent, useLayoutEffect, useState } from "react";
import { ProjectResponseState } from "../../../states/Projects";
import { useHookstate } from "@hookstate/core";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteProjects,
  getProjects,
  updateProjects,
} from "../../../services/Projects";
import Loader from "../../../components/Loader/Loader";
import Header from "../../../components/Header/Header";
import styles from "./EditProjects.module.css";
import { toast } from "react-toastify";

type Props = {};

const EditProjects = (props: Props) => {
  // states data
  // 4.A States
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [deleteText, setDeleteText] = useState("");

  const formData = useHookstate(ProjectResponseState);

  // 4.B Input Handler for Forms
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    formData.set({
      ...formData.get(),
      [name]: value,
    });
  };

  //hooks to fetch data
  useLayoutEffect(() => {
    const getData = async () => {
      await getProjects(id || ``);
    };
    getData();
  }, []);

  //handle button click

  const handleDelete = async () => {
    if (deleteText === "confirm") {
      const response = await deleteProjects(id || ``);
      if (response) {
        navigate("/projects");
      }
    } else {
      toast.error(`Please type confirm`, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    }
  };
  const handleSave = async () => {
    await updateProjects(id || ``, formData.get());
  };

  // Loader
  if (loading) {
    return (
      <>
        <Loader />
      </>
    );
  }

  // UI Components
  return (
    <>
      <Header title={`Projects > ${formData.get().name}`} />
      <div className={styles.section}>
        <div className={styles.form}>
          <div className={styles.inputs}>
            <p className={styles.label}>Created By:</p>
            <p className={styles.form_inputs}>{formData.get().userName}</p>
          </div>

          <div className={styles.inputs}>
            <p className={styles.label}>Project Id</p>
            <input
              className={styles.form_input}
              type="text"
              name="projectId"
              value={formData.get().number}
              onChange={handleInputChange}
            />
          </div>
          <div className={styles.inputs}>
            <p className={styles.label}>Project Name</p>
            <input
              className={styles.form_input}
              type="text"
              name="projectName"
              value={formData.get().name}
              onChange={handleInputChange}
            />
          </div>
          <div className={styles.inputs}>
            <p className={styles.label}>Total Parts</p>
            <p className={styles.form_inputs}>{formData.get().totalDrawings}</p>
          </div>

          <div className={styles.inputs}>
            <p className={styles.label}>Created on: </p>
            <p className={styles.form_inputs}>
              {formData.get().createdAt.toString()}
            </p>
          </div>
        </div>
        <div className={styles.buttons_group}>
          <button className={styles.button} onClick={handleSave}>
            Save
          </button>
          <button
            className={styles.button}
            onClick={() => setIsDelete(!isDelete)}
          >
            Delete
          </button>
        </div>
        {isDelete && (
          <div className={styles.delete_main}>
            <p className={styles.delete_p}>
              This action cannot be undone. Please type “confirm” to proceed.{" "}
            </p>
            <input
              type="text"
              placeholder="Type “confirm”"
              className={styles.delete_input}
              value={deleteText}
              onChange={(e: any) => setDeleteText(e.target.value)}
            />
            <button className={styles.delete_button} onClick={handleDelete}>
              Confirm delete
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default EditProjects;
