// import { ApiResponse } from './ApiResponse'
import { hookstate } from "@hookstate/core";

import { ListData, User } from "../types/Users";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  level: 1,
  id: "",
  createdAt: "",
  phoneNumber: "",
  is_active: false,
  is_staff: false,
  is_superuser: false,
  totalProjects: 0,
  totalDrawings: 0,
  totalParts: 0,
  totalDataCollection: 0,
  totalDataPrediction: 0,
  totalDataPredictionV2: 0,
};
export const UserState = hookstate<User>(initialState);

// export const UserListState = hookstate<Array<User>>([initialState])

export const UsersResponseListState = hookstate<ListData>({
  count: 0,
  next: null,
  previous: null,
  currentPage: 0,
  totalPages: 0,
  results: [],
});
