import React, { useEffect, useState } from "react";
import Header from "../../../components/Header/Header";
import style from "./ListParts.module.css";

import emptyImg from "../../../assets/empty.svg";
import { useHookstate } from "@hookstate/core";
import { PartsResponseListState } from "../../../states/Parts";
import { listParts, multiUpdate } from "../../../services/Parts";
import NextButton from "../../../assets/lesser.svg";
import PrevButton from "../../../assets/greater.svg";

import cancelCross from "../../../assets/cancelCross.svg";
import { endpoints } from "../../../utils/config";
import { Parts } from "../../../types/Parts";
import { SearchFilterUrlHandler } from "../../../utils/searchFilterHandler";
import { useNavigate } from "react-router-dom";
type Props = {};

const ListParts = (props: Props) => {
  const navigate = useNavigate();
  // Table States
  const listData = useHookstate(PartsResponseListState);
  const [dataId, setDataId] = useState<Array<any>>([]);
  const [checkbox, setCheckbox] = useState(false);

  // search states
  const [textSearch, setTextSearch] = useState("");

  const [search, setSearch] = useState(false);

  // filter states
  const [filterFromDate, setFilterFromDate] = useState(``);
  const [filterToDate, setFilterToDate] = useState(``);
  const [filter, setFilter] = useState(false);

  // functions
  // handle all checkbox
  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCheckbox(!checkbox);
    if (e.target.checked) {
      const data = listData.get().results.map((data) => data.id);
      setDataId(data);
    } else {
      setDataId([]);
    }
  };

  // choose one checkbox
  const chooseCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = e.target.name;

    if (dataId.includes(id)) {
      const idCollection = dataId.filter((item) => item !== id);
      setDataId(idCollection);
      setCheckbox(false);
    } else {
      const idCollection = [...dataId];
      idCollection.push(id);
      setDataId((prev) => {
        return [...prev, id];
      });

      if (idCollection.length === listData.results.length) {
        setCheckbox(true);
      }
    }
  };

  const handleAction = async (e: any) => {
    e.preventDefault();
    let requestBody = listData
      .get()
      .results.map((data: Parts) => JSON.parse(JSON.stringify(data)));
    await multiUpdate(requestBody, dataId, e.target.value);
    setCheckbox(false);
    await listParts();
    setDataId([]);
    // window.location.reload()
  };

  // handle previous page
  const handlePreviousPageButton = async (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    if (listData.get().previous) {
      await listParts(listData.get().previous as string);
    }
  };
  // handle next click
  const handleNextPageButton = async (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    if (listData.get().next) {
      await listParts(listData.get().next as string);
    }
  };
  // handle filter bar
  const handleSidebarFilter = async (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    setFilter(true);
    const searchFilterParams = {
      search: textSearch,
      fromDate: filterFromDate,
      toDate: filterToDate,
    };
    const queryUrl = SearchFilterUrlHandler(
      endpoints.parts,
      searchFilterParams
    );
    await listParts(queryUrl);
  };

  const handlefilterClear = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setFilter(false);
    setFilterFromDate(``);
    setFilterToDate(``);
    const searchFilterParams = {
      search: textSearch,
    };
    const queryUrl = SearchFilterUrlHandler(
      endpoints.parts,
      searchFilterParams
    );
    await listParts(queryUrl);
  };

  const clearSearch = async (e: React.MouseEvent<HTMLImageElement>) => {
    e.preventDefault();
    setTextSearch((prev) => "");
    setSearch(false);
    const searchFilterParams = {
      fromDate: filterFromDate,
      toDate: filterToDate,
    };
    const queryUrl = SearchFilterUrlHandler(
      endpoints.parts,
      searchFilterParams
    );
    await listParts(queryUrl);
  };
  const handleSearch = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setSearch(true);
    const searchFilterParams = {
      search: textSearch,
      fromDate: filterFromDate,
      toDate: filterToDate,
    };
    const queryUrl = SearchFilterUrlHandler(
      endpoints.parts,
      searchFilterParams
    );
    await listParts(queryUrl);
  };

  const clearResults = async (e: React.MouseEvent<HTMLButtonElement>) => {
    setSearch(false);
    setFilter(false);
    setTextSearch(``);
    setFilterFromDate(``);
    setFilterToDate(``);
    await listParts();
  };
  // fetch api
  useEffect(() => {
    const getData = async () => {
      await listParts(endpoints.parts);
    };
    getData();
  }, []);
  return (
    <>
      {/* header */}
      <Header title="Parts" />
      {/* main Container */}
      <div className={style.container}>
        <div className={style.searchBarAndTable}>
          <div className={style.searchBar}>
            <div className={style.actionSection}>
              <select className={style.selectAction} onChange={handleAction}>
                <option>Action</option>
                <option value="delete">delete</option>
              </select>
            </div>
            <div className={style.inputBox}>
              <input
                type="text"
                placeholder="Search by project name, part name or username"
                className={style.searchInput}
                value={textSearch}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setTextSearch(e.target.value);
                }}
              />
              {/* Input text cross and button */}
              {/* Search */}
              {textSearch !== "" && (
                <img
                  src={cancelCross}
                  alt=""
                  className={style.cancelSearch}
                  onClick={clearSearch}
                />
              )}
              <button
                className={style.searchBtn}
                type="submit"
                onClick={handleSearch}
              >
                Search
              </button>
            </div>
          </div>

          {(search || filter) && (
            <div className={style.userClearBox}>
              <p className={style.userResult}>Results</p>
              <button className={style.userResultBtn} onClick={clearResults}>
                Clear results
              </button>
            </div>
          )}
          {/* table */}
          <div className={style.tableBox}>
            {/* <div className={style.tableBox}> */}
            {listData && listData.get().results.length > 0 ? (
              <>
                <table className={style.tables}>
                  <thead className={style.tableHead}>
                    <tr>
                      <td>
                        <input
                          type="checkbox"
                          className={style.checkBox}
                          name="header"
                          checked={checkbox}
                          onChange={handleCheckbox}
                        />
                      </td>
                      <td className={style.tableHeadData}>Part Name</td>
                      <td className={style.tableHeadData}>Part id</td>
                      <td className={style.tableHeadData}>Owner</td>
                      <td className={style.tableHeadData}>Drawings</td>
                    </tr>
                  </thead>
                  <tbody>
                    {listData
                      .get()
                      .results.map((item: Parts, index: number) => {
                        return (
                          <tr key={index} className={style.tableRow}>
                            <td>
                              <input
                                type="checkbox"
                                className={style.checkBox}
                                value={item.id}
                                name={item.id}
                                // defaultChecked={dataId.includes(item.id)}
                                checked={dataId.includes(item.id)}
                                onChange={chooseCheckbox}
                              />
                            </td>
                            <td
                              onClick={() =>
                                navigate(`/parts/edit/${item.id}}`)
                              }
                              className={style.tableEditData}
                            >
                              {item.name}
                            </td>
                            <td className={style.tableRowData}>
                              {item.number}
                            </td>
                            <td className={style.tableRowData}>
                              {item.userName}
                            </td>
                            <td className={style.tableRowData}>
                              {item.drawingName}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </>
            ) : (
              <>
                <div className={style.elseTableBlock}>
                  <img src={emptyImg} alt="" className={style.emptyImg} />
                  <h3 className={style.emptyText}>No data available!</h3>
                </div>
              </>
            )}
          </div>
          {/* Pagintation */}

          <div className={style.paginationBar}>
            <div className={style.pagination}>
              <button
                disabled={listData.get().previous ? false : true}
                onClick={handlePreviousPageButton}
                // onClick={async () => {
                //   await listDataCollections(listData.get().previous as string);
                // }}
                className={style.prevBtn}
              >
                <img src={PrevButton} alt="" />
              </button>
              {/* <img src={greater} alt="" className={style.paginationImg} /> */}
              {listData.get().currentPage} to {listData.get().totalPages}
              {/* <img src={lesser} alt="" className={style.paginationImg} /> */}
              <button
                disabled={listData.get().next ? false : true}
                onClick={handleNextPageButton}
                // onClick={async (e:any) => {
                //   e.preventDefault();
                //   await listDataCollections(listData.get().next as string);
                //   window.location.reload()
                // }}
                className={style.prevBtn}
              >
                <img src={NextButton} alt="" />
              </button>
            </div>
          </div>
        </div>
        {/* Filter Section */}
        <div className={style.sideFilter}>
          <div className={style.filterHead}>
            <button className={style.filterLeftP} onClick={handleSidebarFilter}>
              Apply Filters
            </button>
            <button className={style.filterRightP} onClick={handlefilterClear}>
              Clear all
            </button>
          </div>

          <p className={style.filtersText}>Filters</p>
          <h6 className={style.filterCreate}>Select Range</h6>

          <p className={style.toText}>from</p>
          <input
            value={filterFromDate}
            type="date"
            className={style.startDate}
            name="start_date"
            onChange={(e: any) => setFilterFromDate(e.target.value)}
          />

          <p className={style.toText}>to</p>
          <input
            type="date"
            className={style.startDate}
            value={filterToDate}
            onChange={(e: any) => setFilterToDate(e.target.value)}
            name="end_date"
          />
        </div>
      </div>
    </>
  );
};

export default ListParts;
