import React from "react";
import styles from "./MainLayout.module.css";
import sulzerlogo from "../../assets/sulzerlogo.svg";
import { Outlet, NavLink, useNavigate } from "react-router-dom";
import { Logout } from "../../services/Logout";
import { SidebarData } from "./SidebarData";

const MainLayout = () => {
  // logout handler
  const navigate = useNavigate();
  const handleLogout = async (
    e: React.MouseEventHandler<HTMLButtonElement> | any
  ) => {
    const formData = {
      refresh: localStorage.getItem("refreshToken") || ``,
    };
    const success = await Logout(formData);

    if (success) {
      navigate("/login");
      localStorage.clear();
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.sidebar}>
        <img src={sulzerlogo} alt="" className={styles.sulzerlogo} />

        <div className={styles.sidebar_list}>
          {SidebarData.map((item) => {
            return (
              <NavLink
                to={item.path}
                className={({ isActive }) =>
                  isActive ? styles.active : styles.sidebar_items
                }
                key={item.id}
              >
                <p className={styles.sidebar_data}>{item.title}</p>
              </NavLink>
            );
          })}
        </div>

        <button className={styles.logoutBtn} onClick={handleLogout}>
          Logout
        </button>
        <p className={styles.atomicLoops}>Powered by Atomic Loops</p>
      </div>
      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  );
};

export default MainLayout;
