import { endpoints } from "../utils/config";
import { toast } from "react-toastify";
import { ResponseHandler } from "../utils/ResponseHandler";
import {
  Project,
  ApiProjectResponse,
  ProjectRequestBody,
} from "../types/Projects";
import {
  ProjectResponseListState,
  ProjectResponseState,
} from "../states/Projects";

// list projects

export async function listProjects(query?: string): Promise<void> {
  let url = endpoints.projects;
  if (query) {
    url = query;
  }
  const headers = {
    "Content-Type": `application/json`,
    Authorization: `Bearer ${localStorage.getItem(`accessToken`)}`,
    "x-timezone-region": Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  const requestOptions = {
    method: `GET`,
    headers: headers,
  };
  const response = await fetch(url, requestOptions);
  const body = await response.json();
  ResponseHandler(response, body);
  ProjectResponseListState.set(body.data);
  // return body;
}

// create projects
export async function createProjects(
  requestBody: ProjectRequestBody
): Promise<ApiProjectResponse> {
  const url = endpoints.projects;
  const headers = {
    "Content-Type": `application/json`,
    Authorization: `Bearer ${localStorage.getItem(`accessToken`)}`,
    "x-timezone-region": Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  const requestOptions = {
    method: `POST`,
    headers: headers,
    body: JSON.stringify(requestBody),
  };
  const response = await fetch(url, requestOptions);
  const body = await response.json();
  ResponseHandler(response, body);
  return body;
}

// read projects
export async function getProjects(id: string): Promise<void> {
  const url = endpoints.projects + `${id}/`;
  const headers = {
    "Content-Type": `application/json`,
    Authorization: `Bearer ${localStorage.getItem(`accessToken`)}`,
    "x-timezone-region": Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  const requestOptions = {
    method: `GET`,
    headers: headers,
  };
  const response = await fetch(url, requestOptions);
  const body = await response.json();
  const success = ResponseHandler(response, body);
  // return body;
  if (success) {
    ProjectResponseState.set(body.data);
  }
  // else{
  //   destroyProjectResponseState.set({})
  // }
}

// update projects
export async function updateProjects(
  id: string,
  requestBody: Project
): Promise<void> {
  const url = endpoints.projects + `${id}/`;
  const headers = {
    "Content-Type": `application/json`,
    Authorization: `Bearer ${localStorage.getItem(`accessToken`)}`,
    "x-timezone-region": Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  const requestOptions = {
    method: `PATCH`,
    headers: headers,
    body: JSON.stringify(requestBody),
  };
  const response = await fetch(url, requestOptions);
  const body = await response.json();
  const success = ResponseHandler(response, body);
  if (success) {
    ProjectResponseState.set(body.data);
  }
}

// delete projects
export async function deleteProjects(id: string): Promise<boolean> {
  const url = endpoints.projects + `${id}/`;
  const headers = {
    "Content-Type": `application/json`,
    Authorization: `Bearer ${localStorage.getItem(`accessToken`)}`,
    "x-timezone-region": Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  const requestOptions = {
    method: `DELETE`,
    headers: headers,
  };
  const response = await fetch(url, requestOptions);
  if (response.status === 204) {
    toast.success(`Success`, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 1000,
    });
    return true;
  } else {
    const body = await response.json();
    ResponseHandler(response, body);
    return false;
  }
}

// multiple update/delete actions
export async function multiUpdate(
  requestBody: Project[],
  ids: string[],
  action: "delete"
): Promise<void> {
  const url = endpoints.projects + `multiple-delete/`;
  const headers = {
    "Content-Type": `application/json`,
    Authorization: `Bearer ${localStorage.getItem(`accessToken`)}`,
    "x-timezone-region": Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  // filter data
  // eslint-disable-next-line array-callback-return
  requestBody = requestBody.filter((data) => {
    if (ids.includes(data.id)) {
      return data;
    }
  });

  const body = requestBody.map((data) => {
    return {
      id: data.id,
    };
  });
  const requestOptions = {
    method: `POST`,
    headers: headers,
    body: JSON.stringify(body),
  };
  const response = await fetch(url, requestOptions);
}
