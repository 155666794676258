import { hookstate } from "@hookstate/core";
import {
  DataPredictionRequestBody,
  DataPredictionResponse,
  ListData,
} from "../types/DataPrediction";

export const DataPredictionCreateState = hookstate<DataPredictionRequestBody>({
  imageUrl: "",
  modelOnePrediction: "",
  actualOnePrediction: "",
  status: "",
  userId: "",
  projectId: "",
  partId: "",
  drawingId: "",
  modelTwoPrediction: "",
  actualTwoPrediction: "",
  adminOnePrediction: "",
  adminTwoPrediction: "",
});

export const DataPredictionResponseState = hookstate<DataPredictionResponse>({
  imageUrl: "",
  modelOnePrediction: "",
  actualOnePrediction: "",
  userName: "",
  status: "",
  userId: "",
  projectId: "",
  projectName: "",
  drawingName: "",
  componentName: "",
  sketchName: "",
  partId: "",
  partName: "",
  drawingId: "",
  id: "",
  modelTwoPrediction: "",
  actualTwoPrediction: "",
  adminOnePrediction: "",
  adminTwoPrediction: "",
  predictedWeldType: "",
  predictedDefectType: "",
  userPrediction: "",
  createdAt: "",
});

export const DataPredictionResponseListState = hookstate<ListData>({
  count: 0,
  next: null,
  previous: null,
  currentPage: 0,
  totalPages: 0,
  results: [],
});
