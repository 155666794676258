import { useHookstate } from "@hookstate/core";
import React, { useEffect, useState } from "react";
import { ReportGeneratorResponseListState } from "../../../states/ReportGeneration";
import { ReportGenerator } from "../../../types/ReportGeneration";
import {
  listReportGenerations,
  multiUpdate,
} from "../../../services/ReportGeneration";
import Header from "../../../components/Header/Header";
import style from "./ListReportGeneration.module.css";
import cancelCross from "../../../assets/cancelCross.svg";
import emptyImg from "../../../assets/empty.svg";
import NextButton from "../../../assets/lesser.svg";
import PrevButton from "../../../assets/greater.svg";
import { SearchFilterUrlHandler } from "../../../utils/searchFilterHandler";
import { endpoints } from "../../../utils/config";
// import { useNavigate } from "react-router-dom";
type Props = {};

const ListReportGeneration = (props: Props) => {
  // const navigate = useNavigate();
  // Table States
  const listData = useHookstate(ReportGeneratorResponseListState);
  const [dataId, setDataId] = useState<Array<any>>([]);
  const [checkbox, setCheckbox] = useState(false);

  // search states
  const [textSearch, setTextSearch] = useState("");

  const [search, setSearch] = useState(false);

  // filter states
  const [filterFromDate, setFilterFromDate] = useState(``);
  const [filterToDate, setFilterToDate] = useState(``);
  const [filter, setFilter] = useState(false);
  const [status, setStatus] = useState(``);

  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCheckbox(!checkbox);
    if (e.target.checked) {
      const data = listData.get().results.map((data) => data.id);
      setDataId(data);
    } else {
      setDataId([]);
    }
  };

  // choose one checkbox
  const chooseCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = e.target.name;

    if (dataId.includes(id)) {
      const idCollection = dataId.filter((item) => item !== id);
      setDataId(idCollection);
      setCheckbox(false);
    } else {
      const idCollection = [...dataId];
      idCollection.push(id);
      setDataId((prev) => {
        return [...prev, id];
      });

      if (idCollection.length === listData.results.length) {
        setCheckbox(true);
      }
    }
  };

  const handleAction = async (e: any) => {
    e.preventDefault();
    let requestBody = listData
      .get()
      .results.map((data: ReportGenerator) => JSON.parse(JSON.stringify(data)));
    await multiUpdate(requestBody, dataId, e.target.value);
    setCheckbox(false);
    await listReportGenerations();
    setDataId([]);
    // window.location.reload()
  };

  // handle previous page
  const handlePreviousPageButton = async (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    if (listData.get().previous) {
      await listReportGenerations(listData.get().previous as string);
    }
  };
  // handle next click
  const handleNextPageButton = async (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    if (listData.get().next) {
      await listReportGenerations(listData.get().next as string);
    }
  };
  // handle filter bar
  const handleSidebarFilter = async (
    e: React.MouseEvent<HTMLParagraphElement | HTMLButtonElement>
  ) => {
    e.preventDefault();
    setFilter(true);
    const searchFilterParams = {
      search: textSearch,
      fromDate: filterFromDate,
      toDate: filterToDate,
      status: status,
    };
    const queryUrl = SearchFilterUrlHandler(
      endpoints.reportGenerator,
      searchFilterParams
    );
    await listReportGenerations(queryUrl);
  };

  const handleFilterClear = async (
    e: React.MouseEvent<HTMLParagraphElement | HTMLButtonElement>
  ) => {
    e.preventDefault();
    setFilter(false);
    setFilterFromDate(``);
    setFilterToDate(``);
    setStatus(``);
    const searchFilterParams = {
      search: textSearch,
    };
    const queryUrl = SearchFilterUrlHandler(
      endpoints.reportGenerator,
      searchFilterParams
    );
    await listReportGenerations(queryUrl);
  };

  const clearSearch = async (e: React.MouseEvent<HTMLImageElement>) => {
    e.preventDefault();
    setTextSearch((prev) => "");
    setSearch(false);
    const searchFilterParams = {
      fromDate: filterFromDate,
      toDate: filterToDate,
      status: status,
    };
    const queryUrl = SearchFilterUrlHandler(
      endpoints.reportGenerator,
      searchFilterParams
    );
    await listReportGenerations(queryUrl);
  };
  const handleSearch = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setSearch(true);
    const searchFilterParams = {
      search: textSearch,
      fromDate: filterFromDate,
      toDate: filterToDate,
      status: status,
    };
    const queryUrl = SearchFilterUrlHandler(
      endpoints.reportGenerator,
      searchFilterParams
    );
    await listReportGenerations(queryUrl);
  };

  const clearResults = async (e: React.MouseEvent<HTMLButtonElement>) => {
    setSearch(false);
    setFilter(false);
    setTextSearch(``);
    setFilterFromDate(``);
    setFilterToDate(``);
    setStatus(``);
    await listReportGenerations();
  };
  // fetch api
  useEffect(() => {
    const getData = async () => {
      await listReportGenerations();
    };
    getData();
  }, []);
  return (
    <>
      <Header title="Report Generation" />
      <div className={style.container}>
        <div className={style.searchBarAndTable}>
          <div className={style.searchBar}>
            <div className={style.actionSection}>
              <select className={style.selectAction} onChange={handleAction}>
                <option>Action</option>
                <option value="delete">delete</option>
              </select>
            </div>
            <div className={style.inputBox}>
              <input
                type="text"
                placeholder="Search by project, part or drawing name"
                className={style.searchInput}
                value={textSearch}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setTextSearch(e.target.value);
                }}
              />
              {/* Input text cross and button */}
              {/* Search */}
              {textSearch !== "" && (
                <img
                  src={cancelCross}
                  alt=""
                  className={style.cancelSearch}
                  onClick={clearSearch}
                />
              )}
              <button
                className={style.searchBtn}
                type="submit"
                onClick={handleSearch}
              >
                Search
              </button>
            </div>
          </div>
          <div>
            {(search || filter) && (
              <div className={style.userClearBox}>
                <p className={style.userResult}>Results</p>
                <button className={style.userResultBtn} onClick={clearResults}>
                  Clear results
                </button>
              </div>
            )}
            {/* table */}
            <div>
              {/* <div className={style.tableBox}> */}
              {listData && listData.get().results.length > 0 ? (
                <>
                  <table className={style.tables}>
                    <thead className={style.tableHead}>
                      <tr>
                        <td>
                          <input
                            type="checkbox"
                            className={style.checkBox}
                            name="header"
                            checked={checkbox}
                            onChange={handleCheckbox}
                          />
                        </td>
                        <td className={style.tableHeadData}>Project Name</td>
                        <td className={style.tableHeadData}>Part Name</td>
                        <td className={style.tableHeadData}>Drawing Name</td>
                        <td className={style.tableHeadData}>Status</td>
                        <td className={style.tableHeadData}>Created by</td>
                      </tr>
                    </thead>
                    <tbody>
                      {listData
                        .get()
                        .results.map((item: ReportGenerator, index: number) => {

                          return (
                            <tr key={index} className={style.tableRow}>
                              <td>
                                <input
                                  type="checkbox"
                                  className={style.checkBox}
                                  value={item.id}
                                  name={item.id}
                                  // defaultChecked={dataId.includes(item.id)}
                                  checked={dataId.includes(item.id)}
                                  onChange={chooseCheckbox}
                                />
                              </td>
                              <td
                                className={style.tableEditData}
                                // onClick={() =>
                                //   navigate(
                                //     `/report-generation/edit/${item.id}}`
                                //   )
                                // }
                              >
                                {item.projectName}
                              </td>
                              <td className={style.tableRowData}>
                                {item.partName || `----`}
                              </td>
                              <td className={style.tableRowData}>
                                {item.drawingName}
                              </td>
                              <td className={style.tableRowData}>
                                {item.status}
                              </td>
                              <td className={style.tableRowData}>
                                {item.userName}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </>
              ) : (
                <>
                  <div className={style.elseTableBlock}>
                    <img src={emptyImg} alt="" className={style.emptyImg} />
                    <h3 className={style.emptyText}>No data available!</h3>
                  </div>
                </>
              )}
            </div>
            {/* pagination */}
            <div className={style.paginationBar}>
              <div className={style.pagination}>
                <button
                  disabled={listData.get().previous ? false : true}
                  onClick={handlePreviousPageButton}
                  // onClick={async () => {
                  //   await listDataCollections(listData.get().previous as string);
                  // }}
                  className={style.prevBtn}
                >
                  <img src={PrevButton} alt="" />
                </button>
                {/* <img src={greater} alt="" className={style.paginationImg} /> */}
                {listData.get().currentPage} to {listData.get().totalPages}
                {/* <img src={lesser} alt="" className={style.paginationImg} /> */}
                <button
                  disabled={listData.get().next ? false : true}
                  onClick={handleNextPageButton}
                  // onClick={async (e:any) => {
                  //   e.preventDefault();
                  //   await listDataCollections(listData.get().next as string);
                  //   window.location.reload()
                  // }}
                  className={style.prevBtn}
                >
                  <img src={NextButton} alt="" />
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Filter Section */}
        <div className={style.sideFilter}>
          <div className={style.filterHead}>
            <button className={style.filterLeftP} onClick={handleSidebarFilter}>
              Apply Filters
            </button>
            <button className={style.filterRightP} onClick={handleFilterClear}>
              Clear all
            </button>
          </div>
          <p className={style.filtersText}>Filters</p>
          <div>
            <h6 className={style.filterCreate}>Select Range</h6>
            <div>
              <p className={style.toText}>from</p>
              <input
                value={filterFromDate}
                type="date"
                className={style.startDate}
                name="start_date"
                onChange={(e: any) => setFilterFromDate(e.target.value)}
              />
            </div>
            <div>
              <p className={style.toText}>to</p>
              <input
                type="date"
                className={style.startDate}
                value={filterToDate}
                onChange={(e: any) => setFilterToDate(e.target.value)}
                name="end_date"
              />
            </div>
            <div className={style.levelWrapper}>
              <p className={style.filterCreate}> Status</p>
              {[
                {
                  label: "PENDING",
                  value: "PENDING",
                },
                {
                  label: "FAILED",
                  value: "FAILED",
                },
                {
                  label: "SUCCESS",
                  value: "SUCCESS",
                },
              ].map(({ label, value }) => {
                return (
                  <label key={label} className={style.radioSqrBtn2}>
                    <input
                      type="radio"
                      value={status}
                      name="radio-btn"
                      onChange={() => setStatus(value)}
                      className={style.radioBtn}
                    />
                    <span className={style.radioBtnText}>{label}</span>
                  </label>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListReportGeneration;
