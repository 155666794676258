import { toast } from "react-toastify";
import {
  DataCollectionRequestBody,
  DataCollectionResponse,
} from "../types/DataCollection";
import { endpoints } from "../utils/config";
import { ResponseHandler } from "../utils/ResponseHandler";
import {
  DataCollectionCreateState,
  DataCollectionResponseListState,
  DataCollectionResponseState,
} from "../states/DataCollection";

//
//list DataCollections
export async function listDataCollections(query?: string): Promise<void> {
  // con
  let url = endpoints.dataCollection;
  if (query) {
    url = query;
  }
  const headers = {
    "Content-Type": `application/json`,
    Authorization: `Bearer ${localStorage.getItem(`accessToken`)}`,
    "x-timezone-region": Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  const requestOptions = {
    method: `GET`,
    headers: headers,
    // mode: 'cors' as RequestMode
    // redirect: `follow`
  };
  const response = await fetch(url, requestOptions);
  const body = await response.json();
  ResponseHandler(response, body);
  if (body.isSuccess) {
    DataCollectionResponseListState.set(body.data);
  }
  // return body
}

// create DataCollection
export async function createDataCollection(): Promise<void> {
  const url = endpoints.dataCollection;
  const requestBody: DataCollectionRequestBody =
    DataCollectionCreateState.get();
  const headers = {
    "Content-Type": `application/json`,
    Authorization: `Bearer ${localStorage.getItem(`accessToken`)}`,
    "x-timezone-region": Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  const requestOptions = {
    method: `POST`,
    headers: headers,
    body: JSON.stringify(requestBody),
  };
  const response = await fetch(url, requestOptions);
  const body = await response.json();
  ResponseHandler(response, body);
  if (body.isSuccess) {
    DataCollectionResponseState.set(body.data);

    // <Navigate to={endpoints.dataCollection + `${id}/`}/>
  }
  // return body
}

// read DataCollection
export async function getDataCollection(id: string): Promise<void> {
  const url = endpoints.dataCollection + `${id}/`;
  const headers = {
    "Content-Type": `application/json`,
    Authorization: `Bearer ${localStorage.getItem(`accessToken`)}`,
    "x-timezone-region": Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  const requestOptions = {
    method: `GET`,
    headers: headers,
  };
  const response = await fetch(url, requestOptions);
  const body = await response.json();
  ResponseHandler(response, body);
  // return body
  if (body.isSuccess) {
    DataCollectionResponseState.set(body.data);
  }
}

// update DataCollections
export async function updateDataCollection(
  id: string,
  requestBody: DataCollectionResponse
): Promise<void> {
  const url = endpoints.dataCollection + `${id}/`;
  const headers = {
    "Content-Type": `application/json`,
    Authorization: `Bearer ${localStorage.getItem(`accessToken`)}`,
    "x-timezone-region": Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  // const requestBody:DataCollectionRequestBody = DataCollectionCreateState.get()

  const requestOptions = {
    method: `PATCH`,
    headers: headers,
    body: JSON.stringify(requestBody),
  };
  const response = await fetch(url, requestOptions);
  const body = await response.json();
  ResponseHandler(response, body);
  if (body.isSuccess) {
    DataCollectionResponseState.set(body.data);
  }
}

// delete DataCollections
export async function deleteDataCollection(id: string): Promise<boolean> {
  const url = endpoints.dataCollection + `${id}/`;
  const headers = {
    "Content-Type": `application/json`,
    Authorization: `Bearer ${localStorage.getItem(`accessToken`)}`,
    "x-timezone-region": Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  const requestOptions = {
    method: `DELETE`,
    headers: headers,
  };
  const response = await fetch(url, requestOptions);
  if (response.status === 204) {
    toast.success(`Success`, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 1000,
    });
    return true;
  } else {
    const body = await response.json();
    ResponseHandler(response, body);
    return false;
  }
}

// change user to deactivate
export async function multiUpdate(
  requestBody: DataCollectionResponse[],
  ids: string[],
  action: "add" | "remove" | "delete"
): Promise<void> {
  // common part
  const url = endpoints.dataCollection + `multiple-update/`;
  const headers = {
    "Content-Type": `application/json`,
    Authorization: `Bearer ${localStorage.getItem(`accessToken`)}`,
    "x-timezone-region": Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  // filter data
  // eslint-disable-next-line array-callback-return
  requestBody = requestBody.filter((data) => {
    if (ids.includes(data.id)) {
      return data;
    }
  });

  // take action based on action type
  if (action === "add") {
    const body = requestBody.map((data) => {
      return {
        id: data.id,
        status: "ADD",
      };
    });
    const requestOptions = {
      method: `POST`,
      headers: headers,
      body: JSON.stringify(body),
    };
    const response = await fetch(url, requestOptions);
    const responseBody = await response.json();
    ResponseHandler(response, responseBody);
  } else if (action === "remove") {
    const body = requestBody.map((data) => {
      return {
        id: data.id,
        status: "DISCARD",
      };
    });
    const requestOptions = {
      method: `POST`,
      headers: headers,
      body: JSON.stringify(body),
    };
    const response = await fetch(url, requestOptions);
    const responseBody = await response.json();
    ResponseHandler(response, responseBody);
  } else if (action === "delete") {
    const url = endpoints.dataCollection + `multiple-delete/`;
    const body = requestBody.map((data) => {
      return {
        id: data.id,
      };
    });
    const requestOptions = {
      method: `POST`,
      headers: headers,
      body: JSON.stringify(body),
    };
    const response = await fetch(url, requestOptions);
    //   if (response.status === 204) {
    //     await listDataCollections();
    //   }
  }
}

// Preview Image, Project Name
