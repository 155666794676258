import { toast } from "react-toastify";
import { endpoints } from "../utils/config";
import { ResponseHandler } from "../utils/ResponseHandler";
import { User, UpdatePasswordRequest } from "../types/Users";

import { UserState, UsersResponseListState } from "../states/Users";

//list Users
export async function listUsers(query?: string): Promise<void> {
  let url = endpoints.users;
  if (query) {
    url = query;
  }
  const headers = {
    "Content-Type": `application/json`,
    Authorization: `Bearer ${localStorage.getItem(`accessToken`)}`,
    "x-timezone-region": Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  const requestOptions = {
    method: `GET`,
    headers: headers,
  };
  const response = await fetch(url, requestOptions);
  const body = await response.json();
  ResponseHandler(response, body);
  if (body.isSuccess) {
    // UserState
    UsersResponseListState.set(body.data);
  }
  // return false;
  // return body;
}

// create users not implemented in backend

// read user
export async function getUsers(id: string): Promise<boolean> {
  const url = endpoints.users + `${id}/`;
  const headers = {
    "Content-Type": `application/json`,
    Authorization: `Bearer ${localStorage.getItem(`accessToken`)}`,
    "x-timezone-region": Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  const requestOptions = {
    method: `GET`,
    headers: headers,
  };
  const response = await fetch(url, requestOptions);
  const body = await response.json();
  ResponseHandler(response, body);
  if (body.isSuccess) {
    localStorage.setItem("firstName", body.data.firstName);
    localStorage.setItem("lastName", body.data.lastName);
    UserState.set(body.data);
    return true;
  }
  return false;
}

// update user
export async function updateUsers(
  id: string,
  requestBody: User | UpdatePasswordRequest
): Promise<void> {
  const url = endpoints.users + `${id}/`;
  const headers = {
    "Content-Type": `application/json`,
    Authorization: `Bearer ${localStorage.getItem(`accessToken`)}`,
    "x-timezone-region": Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  const requestOptions = {
    method: `PATCH`,
    headers: headers,
    body: JSON.stringify(requestBody),
  };
  const response = await fetch(url, requestOptions);
  const body = await response.json();
  ResponseHandler(response, body);
  if (body.isSuccess) {
    UserState.set(body.data);
  }
}

// delete users
export async function deleteUser(id: string): Promise<boolean> {
  const url = endpoints.users + `${id}/`;
  const headers = {
    "Content-Type": `application/json`,
    Authorization: `Bearer ${localStorage.getItem(`accessToken`)}`,
    "x-timezone-region": Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  const requestOptions = {
    method: `DELETE`,
    headers: headers,
  };
  const response = await fetch(url, requestOptions);
  if (response.status === 204) {
    toast.success(`Success`, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 1000,
    });
    return true;
  } else {
    const body = await response.json();
    ResponseHandler(response, body);
    return false;
  }
}

// change user role
export async function multipleUpdateChangeLevel(
  requestBody: Object
): Promise<void> {
  const url = endpoints.users + `multiple-update/`;
  const headers = {
    "Content-Type": `application/json`,
    Authorization: `Bearer ${localStorage.getItem(`accessToken`)}`,
    "x-timezone-region": Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  const requestOptions = {
    method: `POST`,
    headers: headers,
    body: JSON.stringify(requestBody),
  };
  const response = await fetch(url, requestOptions);
  const body = await response.json();
  ResponseHandler(response, body);
  // if (body.isSuccess) {
  //   UsersResponseListState.set(body.data);
  // }
}

// change user to Activate
export async function multipleActivateAccount(
  requestBody: Object
): Promise<void> {
  const url = endpoints.users + `multiple-update/`;
  const headers = {
    "Content-Type": `application/json`,
    Authorization: `Bearer ${localStorage.getItem(`accessToken`)}`,
    "x-timezone-region": Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  const requestOptions = {
    method: `POST`,
    headers: headers,
    body: JSON.stringify(requestBody),
  };
  const response = await fetch(url, requestOptions);
  const body = await response.json();
  ResponseHandler(response, body);
  // if (body.isSuccess) {
  //   UsersResponseListState.set(body.data);
  // }
}

// change user to deactivate
export async function multipleDeactivateAccount(
  requestBody: Object
): Promise<void> {
  const url = endpoints.users + `multiple-update/`;
  const headers = {
    "Content-Type": `application/json`,
    Authorization: `Bearer ${localStorage.getItem(`accessToken`)}`,
    "x-timezone-region": Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  const requestOptions = {
    method: `POST`,
    headers: headers,
    body: JSON.stringify(requestBody),
  };
  const response = await fetch(url, requestOptions);
  const body = await response.json();
  console.log(body.isSuccess);
  console.log('aslkfdjasdlkadsf;lk;jkldsaf')
  ResponseHandler(response, body);
  // if (body.isSuccess) {
  //   // UsersResponseListState.set(body.data);
  // }
}

// change user to deactivate
export async function multiUpdate(
  requestBody: User[],
  ids: string[],
  action: "activate" | "deactivate" | "role"
): Promise<void> {
  requestBody = requestBody.filter((data) => {
    if (ids.includes(data.id)) {
      return data;
    }
  });
  // const url = endpoints.users + `multi-update/`
  // const headers = {
  //   "Content-Type": `application/json`,
  //   Authorization: `Bearer ${localStorage.getItem(`accessToken`)}`,
  // };
  // const requestOptions = {
  //   method: `PATCH`,
  //   headers: headers,
  //   body: JSON.stringify(requestBody),
  // };
  // const response = await fetch(url, requestOptions);
  // const body = await response.json();
  // ResponseHandler(response, body);
  // if (body.isSuccess){
  //   UsersResponseListState.set(body.data)
  // }
}
