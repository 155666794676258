import { endpoints } from "../utils/config";
import { ResponseHandler } from "../utils/ResponseHandler";
import {StatisticsState} from '../states/Statistics'

// get stats 
export async function getStatistic(): Promise<void> {
  const url = endpoints.statistics;
  const headers = {
    "Content-Type": `application/json`,
    Authorization: `Bearer ${localStorage.getItem(`accessToken`)}`,
    "x-timezone-region": Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  const requestOptions = {
    method: `GET`,
    headers: headers,
  };
  const response = await fetch(url, requestOptions);
  const body = await response.json();
  ResponseHandler(response, body);
  if (body.isSuccess) {
    StatisticsState.set(body.data)
  }
  return body;
}
