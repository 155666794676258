import { useHookstate } from "@hookstate/core";
import React, { ChangeEvent, useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserState } from "../../../states/Users";
import { deleteUser, getUsers, updateUsers } from "../../../services/Users";
import { toast } from "react-toastify";
import Header from "../../../components/Header/Header";
import styles from "./EditUsers.module.css";

type Props = {};

const EditUsers = (props: Props) => {
  //states
  const { id } = useParams();
  const navigate = useNavigate();
  const formData = useHookstate(UserState);

  const [deleteText, setDeleteText] = useState();
  const [isDelete, setIsDelete] = useState(false);

  // get data
  useLayoutEffect(() => {
    const getData = async () => {
      await getUsers(id || ``);
    };
    getData();
  }, []);

  // delete users
  const handleDelete = async () => {
    if (deleteText === "confirm") {
      const response = await deleteUser(id || ``);
      if (response) {
        navigate("/users");
      }
    } else {
      toast.error(`Please type confirm`, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    }
  };

  // handle save
  const handleSave = async () => {
    const body = JSON.parse(JSON.stringify(formData.get()));

    delete body["id"];
    await updateUsers(id || ``, body);
  };

  // 4.B Input Handler for Forms
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    formData.set({ ...formData.get(), [name]: value });
  };

  // UI Components
  return (
    <>
      <Header title="Update User" />
      <div className={styles.section}>
        <div className={styles.form}>
          <div className={styles.inputs}>
            <p className={styles.label}>First Name:</p>
            <input
              className={styles.form_input}
              type="text"
              name="firstName"
              value={formData.get().firstName}
              onChange={handleInputChange}
            />
          </div>

          <div className={styles.inputs}>
            <p className={styles.label}>Last Name:</p>
            <input
              className={styles.form_input}
              type="text"
              name="lastName"
              value={formData.get().lastName}
              onChange={handleInputChange}
            />
          </div>

          <div className={styles.inputs}>
            <p className={styles.label}>Email</p>
            <p className={styles.form_inputs}>{formData.get().email}</p>
          </div>

          <div className={styles.inputs}>
            <p className={styles.label}>Phone Number:</p>
            <input
              className={styles.form_input}
              type="text"
              name="phoneNumber"
              value={formData.get().phoneNumber}
              onChange={handleInputChange}
            />
          </div>

          <div className={styles.inputs}>
            <p className={styles.label}>Total Data Collection OLD</p>
            <p className={styles.form_inputs}>
              {formData.get().totalDataCollection}
            </p>
          </div>
          <div className={styles.inputs}>
            <p className={styles.label}>Total Data Collection NEW</p>
            <p className={styles.form_inputs}>
              {formData.get().totalDataPredictionV2}
            </p>
          </div>

          <div className={styles.inputs}>
            <p className={styles.label}>Total Data Prediction</p>
            <p className={styles.form_inputs}>
              {formData.get().totalDataPrediction}
            </p>
          </div>
          <div className={styles.inputs}>
            <p className={styles.label}>Total Projects</p>
            <p className={styles.form_inputs}>{formData.get().totalProjects}</p>
          </div>

          <div className={styles.inputs}>
            <p className={styles.label}>Total Parts</p>
            <p className={styles.form_inputs}>{formData.get().totalParts}</p>
          </div>

          <div className={styles.inputs}>
            <p className={styles.label}>Total Drawings</p>
            <p className={styles.form_inputs}>{formData.get().totalDrawings}</p>
          </div>

          <div className={styles.inputs}>
            <p className={styles.label}>Created on: </p>
            <p className={styles.form_inputs}>
              {formData.get().createdAt?.toString()}
            </p>
          </div>
        </div>
        <div className={styles.buttons_group}>
          <button className={styles.button} onClick={handleSave}>
            Save
          </button>
          <button
            className={styles.button}
            onClick={() => setIsDelete(!isDelete)}
          >
            Deactivate Account
          </button>
        </div>
        {isDelete && (
          <div className={styles.delete_main}>
            <p className={styles.delete_p}>
              This action cannot be undone. Please type “confirm” to proceed.{" "}
            </p>
            <input
              type="text"
              placeholder="Type “confirm”"
              className={styles.delete_input}
              value={deleteText}
              onChange={(e: any) => setDeleteText(e.target.value)}
            />
            <button className={styles.delete_button} onClick={handleDelete}>
              Confirm Deactivate
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default EditUsers;
