import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  EditDataCollection,
  EditDataPrediction,
  EditDataPredictionV2,
  EditDrawings,
  EditParts,
  EditProjects,
  EditUsers,
  ListDataCollection,
  ListDataPrediction,
  ListDataPredictionV2,
  ListDrawings,
  ListParts,
  ListProjects,
  ListReportGeneration,
  ListUsers,
  Login,
  Statistics,
  NotFound,
} from "./pages";
import ProtectedRoute from "./utils/ProtectedRoute";
import MainLayout from "./components/MainLayout/MainLayout";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./pages/Dashboard/Dashboard";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./pages/ResetPassword/ResetPassword";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* Login Route */}
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/" element={<MainLayout />}>
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            {/* Users Routes */}
            <Route
              path="/users"
              element={
                <ProtectedRoute>
                  <ListUsers />
                </ProtectedRoute>
              }
            />

            <Route
              path="/users/edit/:id"
              element={
                <ProtectedRoute>
                  <EditUsers />
                </ProtectedRoute>
              }
            />

            {/* Data Collection Routes */}
            <Route
              path="/data-collection"
              element={
                <ProtectedRoute>
                  <ListDataCollection />
                </ProtectedRoute>
              }
            />

            <Route
              path="/data-collection/edit/:id"
              element={
                <ProtectedRoute>
                  <EditDataCollection />
                </ProtectedRoute>
              }
            />

            {/* Projects  Routes */}
            <Route
              path="/projects"
              element={
                <ProtectedRoute>
                  <ListProjects />
                </ProtectedRoute>
              }
            />

            <Route
              path="/projects/edit/:id"
              element={
                <ProtectedRoute>
                  <EditProjects />
                </ProtectedRoute>
              }
            />

            {/* Parts  Routes */}
            <Route
              path="/parts"
              element={
                <ProtectedRoute>
                  <ListParts />
                </ProtectedRoute>
              }
            />

            <Route
              path="/parts/edit/:id"
              element={
                <ProtectedRoute>
                  <EditParts />
                </ProtectedRoute>
              }
            />

            {/* Drawing  Routes */}
            <Route
              path="/drawings"
              element={
                <ProtectedRoute>
                  <ListDrawings />
                </ProtectedRoute>
              }
            />

            <Route
              path="/drawings/edit/:id"
              element={
                <ProtectedRoute>
                  <EditDrawings />
                </ProtectedRoute>
              }
            />

            {/* Data  Prediction Routes */}
            <Route
              path="/data-prediction"
              element={
                <ProtectedRoute>
                  <ListDataPrediction />
                </ProtectedRoute>
              }
            />

            <Route
              path="/data-prediction/edit/:id"
              element={
                <ProtectedRoute>
                  <EditDataPrediction />
                </ProtectedRoute>
              }
            />

            {/* Data  Prediction V2 Routes */}
            <Route
              path="/data-prediction-v2"
              element={
                <ProtectedRoute>
                  <ListDataPredictionV2 />
                </ProtectedRoute>
              }
            />

            <Route
              path="/data-prediction-v2/edit/:id"
              element={
                <ProtectedRoute>
                  <EditDataPredictionV2 />
                </ProtectedRoute>
              }
            />
             <Route
              path="/stats"
              element={
                <ProtectedRoute>
                  <Statistics />
                </ProtectedRoute>
              }
            />

            {/* Report Generation  Routes */}
            <Route
              path="/report-generation"
              element={
                <ProtectedRoute>
                  <ListReportGeneration />
                </ProtectedRoute>
              }
            />
          </Route>

          
          {/* Not Found  Routes */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer transition={Zoom} />
    </>
  );
}

export default App;
