const SearchFilterUrlHandler = (
  url: string,
  searchFilter: { [key: string]: any }
) => {
  let finalUrl = "";
  for (const property in searchFilter) {
    if (
      searchFilter[property] !== undefined &&
      searchFilter[property] !== null &&
      searchFilter[property] !== ""
    ) {
      if (Array.isArray(searchFilter[property])) {
        if (searchFilter[property].length !== 0) {
          finalUrl += "&" + property + "=" + searchFilter[property];
        }
      } else {
       
        finalUrl += "&" + property + "=" + searchFilter[property];
      }
    }
  }
  finalUrl = url + finalUrl.replace("&", "?").replaceAll(" ", "+");
  return finalUrl;
};

export { SearchFilterUrlHandler };
