export const SidebarData = [
  {
    id: 1,
    title: "ML Dashboard",
    path: "/dashboard",
  },
  {
    id: 2,
    title: "Users",
    path: "/users/",
  },
  {
    id: 3,
    title: "Data Collection",
    path: "/data-collection/",
  },
  {
    id: 4,
    title: "Data Collection New",
    path: "/data-prediction-v2/",
  },
  {
    id: 5,
    title: "Projects",
    path: "/projects/",
  },
  {
    id: 6,
    title: "Drawings",
    path: "/drawings/",
  },
  {
    id: 7,
    title: "Parts",
    path: "/parts/",
  },

  {
    id: 8,
    title: "Data Prediction",
    path: "/data-prediction/",
  },
  {
    id: 9,
    title: "Report Generation",
    path: "/report-generation/",
  },

  {
    id: 10,
    title: "Stats",
    path: "/stats/",
  },
];
