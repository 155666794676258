import { hookstate } from "@hookstate/core";
import {
  DataPredictionV2RequestBody,
  DataPredictionV2Response,
  ListData,
} from "../types/DataPredictionV2";

export const DataPredictionV2CreateState = hookstate<DataPredictionV2RequestBody>({
  imageUrl: "",
  modelOnePrediction: "",
  actualOnePrediction: "",
  status: "",
  userId: "",
  modelTwoPrediction: "",
  actualTwoPrediction: "",
  adminOnePrediction: "",
  adminTwoPrediction: "",
});

export const DataPredictionV2ResponseState = hookstate<DataPredictionV2Response>({
  imageUrl: "",
  modelOnePrediction: "",
  actualOnePrediction: "",
  userName: "",
  status: "",
  userId: "",
  id: "",
  modelTwoPrediction: "",
  actualTwoPrediction: "",
  adminOnePrediction: "",
  adminTwoPrediction: "",
  predictedWeldType: "",
  predictedDefectType: "",
  userPrediction: "",
  createdAt: "",
});

export const DataPredictionV2ResponseListState = hookstate<ListData>({
  count: 0,
  next: null,
  previous: null,
  currentPage: 0,
  totalPages: 0,
  results: [],
});
