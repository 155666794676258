import { hookstate } from "@hookstate/core";
import {
  ReportGeneratorRequestBody,
  ReportGenerator,
  ListData,
} from "../types/ReportGeneration";

export const ReportGeneratorCreateState = hookstate<ReportGeneratorRequestBody>(
  {
    userId: "",
    projectId: "",
    partId: "",
    drawingId: "",
    fromDate: "",
    toDate: "",
  }
);

const initialState = {
  id: "",
  status: "",
  userId: "",
  projectId: "",
  projectName: "",
  partName: "",
  reportUrl: "",
  partId: "",
  drawingId: "",
  drawingName: "",
  createdAt: "",
  userName: "",
};

export const ReportGeneratorResponseState =
  hookstate<ReportGenerator>(initialState);

export const ReportGeneratorResponseListState = hookstate<ListData>({
  count: 0,
  next: null,
  previous: null,
  currentPage: 0,
  totalPages: 0,
  results: [initialState],
});
