import { endpoints } from "../utils/config";
import { ResponseHandler } from "../utils/ResponseHandler";
// import {}
import { ResetPasswordForm } from "../types/ResetPassword";


import { ResetPasswordState } from "../states/ResetPassword";
// const navigate = useNavigate()

//list parts

// create part
export async function resetPassword(): Promise<boolean> {
  // const n = useNavigate()
  const requestBody: ResetPasswordForm = ResetPasswordState.get();
  const url = endpoints.resetPassword;
  const headers = {
    "Content-Type": `application/json`,
    "x-timezone-region": Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  const requestOptions = {
    method: `POST`,
    headers: headers,
    body: JSON.stringify(requestBody),
  };
  const response = await fetch(url, requestOptions);
  const body = await response.json();
  ResponseHandler(response, body);
  if (body.isSuccess) {
    return true;
  }
  
  return false;
}
