import { toast } from 'react-toastify';

interface ErrorProp {
    message?: any
}

export interface props{
    data: object,
    isSuccess: boolean
    error: ErrorProp
}

const handlerErrorMessage = (error: ErrorProp) =>{
    // console.log(`I am here`, error)
    if (typeof(error?.message) === 'string'){
        // console.log(` I am string`)
        return error.message;
    }
    else if (typeof(error?.message) === 'object'){
        // console.log(` I am object`, error.message)
        for (const key in error.message){
    
            return `${key}: ${error.message[key]}`;
        }

    }
    else if (Array.isArray(error.message)){
        return error.message[0]
    }
    return JSON.stringify(error.message)
}

export function ResponseHandler(response:Response, body: props){
    try{

        if (body.isSuccess) {
           // toast.success(`Success`, {
             //   position: toast.POSITION.TOP_CENTER,
              //  autoClose: 1000,
            //})
            return true
        }
        else {
           
            const message:string| object = handlerErrorMessage(body.error)
            toast.error(`${JSON.stringify(message)}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 1000,
            })
            return false

        }
        
    }
    catch (error) {
        toast.error(`${JSON.stringify(error)}`, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
        })
        return false
    }
}

