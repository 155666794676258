import React, { useEffect, useState } from "react";
import Header from "../../../components/Header/Header";
import style from "./ListUsers.module.css";
import NextButton from "../../../assets/lesser.svg";
import PrevButton from "../../../assets/greater.svg";

import { useHookstate } from "@hookstate/core";
import { UsersResponseListState } from "../../../states/Users";
import {
  listUsers,
  multiUpdate,
  multipleActivateAccount,
  multipleDeactivateAccount,
  multipleUpdateChangeLevel,
} from "../../../services/Users";
import emptyImg from "../../../assets/empty.svg";
import { User } from "../../../types/Users";
import cancelCross from "../../../assets/cancelCross.svg";
import { endpoints } from "../../../utils/config";
import { SearchFilterUrlHandler } from "../../../utils/searchFilterHandler";
import { useNavigate } from "react-router-dom";
type Props = {};

const ListUsers = (props: Props) => {
  const options = [
    { label: "Level 0", value: 0 },
    { label: "Level 1", value: 1 },
    { label: "Level 5", value: 5 },
  ];
  const navigate = useNavigate();
  // Table States
  const [filterFromDate, setFilterFromDate] = useState(``);
  const [filterToDate, setFilterToDate] = useState(``);
  const [checkBox, setCheckBox] = useState(false);
  const [userLevel, setUserLevel] = useState<Array<string>>([]);
  const [active, setActive] = useState(``);

  const [filter, setFilter] = useState(false);

  // local state

  const [textSearch, setTextSearch] = useState("");

  const [search, setSearch] = useState(false);
  // states
  const listData = useHookstate(UsersResponseListState);
  const [dataId, setDataId] = useState<Array<any>>([]);

  const handleAllCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setCheckBox(!checkBox);
    if (e.target.checked) {
      const data = listData.get().results.map((data) => data.id);
      setDataId(data);
    } else {
      setDataId([]);
    }
  };
  const chooseCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = e.target.value;


    if (dataId.includes(id)) {
      const idCollection = dataId.filter((item) => item !== id);
      setDataId(idCollection);
      setCheckBox(false);
    } else {
      const idCollection = [...dataId];
      idCollection.push(id);
      setDataId((prev) => {
        return [...prev, id];
      });

      if (idCollection.length === listData.results.length) {
        setCheckBox(true);
      }
    }
  };

  // handle action
  const handleAction = async (e: any) => {
    e.preventDefault();
    let requestBody = listData.results.map((data: any) =>
      JSON.parse(JSON.stringify(data.get()))
    );

    // eslint-disable-next-line array-callback-return
    requestBody = requestBody.filter((data) => {
      if (dataId.includes(data.id)) {
        return data;
      }
    });
    if (e.target.value === "0") {
      requestBody = requestBody.map((data: any) => {
        return {
          id: data.id,
          level: 5,
          is_superuser: true
        };
      });
      await multipleUpdateChangeLevel(requestBody);
      setDataId([]);
    }
    if (e.target.value === "1") {
      requestBody = requestBody.map((data: any) => {
        return {
          id: data.id,
          is_active: true,
        };
      });
      await multipleActivateAccount(requestBody);
      setDataId([]);
    }
    if (e.target.value === "2") {
      requestBody = requestBody.map((data: any) => {
        return {
          id: data.id,
          is_active: false,
        };
      });
      await multipleDeactivateAccount(requestBody);
      setDataId([]);
      
    }

    // if (e.target.value === "3") {
    //   x = listData.get
    //   requestBody = requestBody.map((data: any) => {
    //     return {
    //       id: data.id,
    //       is_active: false,
    //     };
    //   });
    //   await multipleDeactivateAccount(requestBody);
    //   setDataId([]);
    // }
    const x: Array<User> = listData.get().results.map((data: User) => {
      return JSON.parse(JSON.stringify(data));
    });
    await multiUpdate(x, dataId, "activate");
  };

  // functions

  const handleSidebarFilter = async (
    e: React.MouseEvent<HTMLParagraphElement | HTMLButtonElement>
  ) => {
    e.preventDefault();
    setFilter(true);
    const searchFilterParams = {
      search: textSearch,
      fromDate: filterFromDate,
      toDate: filterToDate,
      is_active: (active === `Active`)? true: (active === `Inactive`)? false: null,
      level: userLevel,
    };
    const queryUrl = SearchFilterUrlHandler(
      endpoints.users,
      searchFilterParams
    );
    await listUsers(queryUrl);
  };
  const handleFilterClear = async (
    e: React.MouseEvent<HTMLParagraphElement | HTMLButtonElement>
  ) => {
    e.preventDefault();
    setFilter(false);
    setFilterFromDate(``);
    setFilterToDate(``);
    setActive(``);
    setUserLevel([]);
    const searchFilterParams = {
      search: textSearch,
    };
    const queryUrl = SearchFilterUrlHandler(
      endpoints.users,
      searchFilterParams
    );
    await listUsers(queryUrl);
  };
  const clearSearch = async (e: React.MouseEvent<HTMLImageElement>) => {
    e.preventDefault();
    setTextSearch((prev) => "");
    setSearch(false);
    const searchFilterParams = {
      fromDate: filterFromDate,
      toDate: filterToDate,
      level: userLevel,
    };
    const queryUrl = SearchFilterUrlHandler(
      endpoints.users,
      searchFilterParams
    );
    await listUsers(queryUrl);
  };

  const clearResults = async (e: React.MouseEvent<HTMLButtonElement>) => {
    setSearch(false);
    setFilter(false);
    setTextSearch(``);
    setUserLevel([]);
    // setFilterFromDate(``);
    // setFilterToDate(``);
    // setWeldType(``);
    // setDefectType([]);

    await listUsers();
  };
  const handleUserLevel = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = e.target.value;
    if (userLevel.includes(id)) {
      const idCollection = userLevel.filter((item) => item !== id);
      setUserLevel(idCollection);
    } else {
      const idCollection = [...userLevel];
      idCollection.push(id);
      setUserLevel(idCollection);
    }
  };
  // handle buttons
  const handleSearch = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setSearch(true);
    const searchFilterParams = {
      search: textSearch,
      fromDate: filterFromDate,
      toDate: filterToDate,
      is_active: active,
      level: userLevel,
      // typeOfWeld: weldType,
      // typeOfDefect: defectType,
    };
    const queryUrl = SearchFilterUrlHandler(
      endpoints.users,
      searchFilterParams
    );
    await listUsers(queryUrl);
  };

  // handle previous page
  const handlePreviousPageButton = async (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    if (listData.get().previous) {
      await listUsers(listData.get().previous as string);
    }
  };
  // handle next click
  const handleNextPageButton = async (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    if (listData.get().next) {
      await listUsers(listData.get().next as string);
    }
  };

  // fetch api
  useEffect(() => {
    const getData = async () => {
      await listUsers();
    };
    getData();
  }, []);
  return (
    <>
      {/* header */}
      <Header title={"Users"} />
      {/* main Container */}
      <div className={style.container}>
        <div className={style.searchBarAndTable}>
          {/* SearchBar */}
          <div className={style.searchBar}>
            <select className={style.selectAction} onChange={handleAction}>
              <option>Action</option>
              <option value="0">Change Role to Admin</option>
              <option value="1">Activate Account</option>
              <option value="2">Deactivate Account</option>
            </select>
            <div className={style.inputBox}>
              <input
                type="text"
                placeholder="Search by Name"
                value={textSearch}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setTextSearch(e.target.value);
                }}
                className={style.searchInput}
              />
              {textSearch !== "" && (
                <img
                  src={cancelCross}
                  alt=""
                  className={style.cancelSearch}
                  onClick={clearSearch}
                />
              )}
              <button
                className={style.searchBtn}
                type="submit"
                onClick={handleSearch}
              >
                Search
              </button>
            </div>
          </div>
          {/* table */}
          {(search || filter) && (
            <div className={style.userClearBox}>
              <p className={style.userResult}>Results</p>
              <button className={style.userResultBtn} onClick={clearResults}>
                Clear results
              </button>
            </div>
          )}
          <div className={style.tableBox}>
          
            {listData && listData.get().results.length > 0 ? (
              <>
                <table className={style.tables}>
                  <thead className={style.tableHead}>
                    <tr>
                      <td>
                        <input
                          type="checkbox"
                          className={style.checkBox}
                          name="header"
                          checked={checkBox}
                          onChange={handleAllCheckBox}
                        />
                      </td>
                      <td className={style.tableHeadData}>User Name</td>
                      <td className={style.tableHeadData}>User level</td>
                      <td className={style.tableHeadData}>Data collected OLD</td>
                      <td className={style.tableHeadData}>Data collected NEW</td>
                      <td className={style.tableHeadData}>Data predicted</td>
                    </tr>
                  </thead>
                  <tbody>
                    {listData.get().results.map((item: any, index: number) => {
                      return (
                        <tr key={index} className={style.tableRow}>
                          <td>
                            <input
                              type="checkbox"
                              className={style.checkBox}
                              value={item.id}
                              name={item.id}
                              checked={dataId.includes(item.id)}
                              onChange={chooseCheckbox}
                            />
                          </td>
                          <td
                            onClick={() => navigate(`/users/edit/${item.id}}`)}
                            className={style.tableEditData}
                          >
                            {item.firstName + " " + item.lastName}
                          </td>
                          <td className={style.tableRowData}>{item.level}</td>
                          <td className={style.tableRowData}>
                            {item.totalDataCollection}
                          </td>
                          <td className={style.tableRowData}>
                            {item.totalDataPredictionV2}
                          </td>
                          <td className={style.tableRowData}>
                            {item.totalDataPrediction}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>
            ) : (
              <>
                <div className={style.elseTableBlock}>
                  <img src={emptyImg} alt="" className={style.emptyImg} />
                  <h3 className={style.emptyText}>No data available!</h3>
                </div>
              </>
            )}
          </div>
          {/* pagination */}
          <div className={style.paginationBar}>
            <div className={style.pagination}>
              <button
                disabled={listData.get().previous ? false : true}
                onClick={handlePreviousPageButton}
                // onClick={async (e: any) => {
                //   e.preventDefault();
                //   await listUsers(listData.get().previous as string);
                // }}
                className={style.prevBtn}
              >
                <img src={PrevButton} alt="" />
              </button>
              {/* <img src={greater} alt="" className={style.paginationImg} /> */}
              {listData.get().currentPage} to {listData.get().totalPages}
              {/* <img src={lesser} alt="" className={style.paginationImg} /> */}
              <button
                disabled={listData.get().next ? false : true}
                onClick={handleNextPageButton}
                // onClick={async (e: any) => {
                //   e.preventDefault();
                //   await listUsers(listData.get().next as string);
                //   // window.location.reload();
                // }}
                className={style.prevBtn}
              >
                <img src={NextButton} alt="" />
              </button>
            </div>
          </div>
        </div>
        {/* sidebar filter */}
        <div className={style.sideFilter}>
          <div className={style.filterHead}>
            <button className={style.filterLeftP} onClick={handleSidebarFilter}>
              Apply Filters
            </button>
            <button className={style.filterRightP} onClick={handleFilterClear}>
              Clear all
            </button>
          </div>
          <p className={style.filtersText}>Filters</p>
          <h6 className={style.filterCreate}>Select Range</h6>

          <p className={style.toText}>from</p>
          <input
            value={filterFromDate}
            type="date"
            className={style.startDate}
            name="start_date"
            onChange={(e: any) => setFilterFromDate(e.target.value)}
          />
          <p className={style.toText}>to</p>
          <input
            type="date"
            className={style.startDate}
            value={filterToDate}
            onChange={(e: any) => setFilterToDate(e.target.value)}
            name="end_date"
          />

          <h6 className={style.filterCreate}>User level</h6>
          <div className={style.levelWrapper}>
            {options.map(({ label, value }) => {
              return (
                <label key={value} className={style.radioSqrBtn2}>
                  <input
                    type="checkbox"
                    value={value}
                    name={label}
                    onChange={handleUserLevel}
                    // checked={userLevel.includes(value)}
                    className={style.radioBtn}
                  />
                  <span className={style.radioBtnText}>{label}</span>
                </label>
              );
            })}
          </div>

          {/* Active Users */}
          <h6 className={style.filterCreate}>Active Users</h6>
          <div className={style.levelWrapper}>
            {[
              {
                label: "Active",
                value: "Active",
              },
              {
                label: "Inactive",
                value: "Inactive",
              },
            ].map(({ label, value }) => {
              return (
                <label key={label} className={style.radioSqrBtn2}>
                  <input
                    type="radio"
                    value={active}
                    name="radio-btn"
                    onChange={() => setActive(value)}
                    className={style.radioBtn}
                  />
                  <span className={style.radioBtnText}>{label}</span>
                </label>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ListUsers;
