import "../../styles/FormInput.module.css";

import { ChangeEvent } from "react";
import style from "../../styles/FormInput.module.css";

export interface IFormInputProps {
  content?: string;
  type?: string;
  placeholder?: string;
  value?: any;
  name: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}

export default function FormInput(props: IFormInputProps) {
  return (
    <>
      <div className={style.formInput}>
        <label className={style.formLabel}>{props.content}</label>
        <input
          type={props.type}
          className={style.inputField}
          placeholder={props.placeholder}
          value={props.value}
          name={props.name}
          onChange={props.onChange}
        />
      </div>
    </>
  );
}
