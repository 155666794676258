import React from "react";
import style from "./Header.module.css";
import adminProfile from "../../assets/adminProfile.svg";
// import lesser from "../../assets/lesser.svg";

type Props = {
  title: string;
};

const Header = (props: Props) => {
  return (
    <>
      {/* <div className={s.container}> */}
      <div className={style.componentBlock}>
        <div className={style.leftSide}>
          <h3 className={style.head}>Admin Panel</h3>
          <p className={style.paras}>{props.title}</p>
        </div>
        <div className={style.rightSide}>
          <p className={style.paras} id={style.user}>
            Hello <br /> {localStorage.getItem("firstName")}!
          </p>
          <img src={adminProfile} alt="" className={style.adminProfile} />
        </div>
      </div>
      {/* </div> */}

      {/* <div className={s.componentName}>
          <p className={s.paras}>{props.title}</p>
          <div className={s.profileBlock}>
            <p className={s.adminName}>
              Hello <br /> {localStorage.getItem("firstName")}
            </p>
            <img src={adminProfile} alt="" className={s.admin_profile} />
          </div>
        </div> */}
    </>
  );
};

export default Header;
