

import { toast } from "react-toastify";
import {
  DataPredictionV2RequestBody,
  DataPredictionV2Response,
} from "../types/DataPredictionV2";
import { endpoints } from "../utils/config";
import { ResponseHandler } from "../utils/ResponseHandler";
import {
  DataPredictionV2CreateState,
  DataPredictionV2ResponseListState,
  DataPredictionV2ResponseState,
} from "../states/DataPredictionV2";

//list DataP`redictionV2s
export async function listDataPredictionV2(query?: string): Promise<void> {
  // con
  let url = endpoints.dataPredictionV2;
  if (query) {
    url = query;
  }
  const headers = {
    "Content-Type": `application/json`,
    Authorization: `Bearer ${localStorage.getItem(`accessToken`)}`,
    "x-timezone-region": Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  const requestOptions = {
    method: `GET`,
    headers: headers,
  };
  const response = await fetch(url, requestOptions);
  const body = await response.json();
  ResponseHandler(response, body);
  if (body.isSuccess) {
    DataPredictionV2ResponseListState.set(body.data);
  }
  // return body
}

// create DataPredictionV2
export async function createDataPredictionV2(): Promise<void> {
  const url = endpoints.dataPredictionV2;
  const requestBody: DataPredictionV2RequestBody =
    DataPredictionV2CreateState.get();
  const headers = {
    "Content-Type": `application/json`,
    Authorization: `Bearer ${localStorage.getItem(`accessToken`)}`,
    "x-timezone-region": Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  const requestOptions = {
    method: `POST`,
    headers: headers,
    body: JSON.stringify(requestBody),
  };
  const response = await fetch(url, requestOptions);
  const body = await response.json();
  ResponseHandler(response, body);
  if (body.isSuccess) {
    DataPredictionV2ResponseState.set(body.data);

    // <Navigate to={endpoints.DataPredictionV2 + `${id}/`}/>
  }
  // return body
}

// read DataPredictionV2
export async function getDataPredictionV2(id: string): Promise<void> {
  const url = endpoints.dataPredictionV2 + `${id}/`;
  const headers = {
    "Content-Type": `application/json`,
    Authorization: `Bearer ${localStorage.getItem(`accessToken`)}`,
    "x-timezone-region": Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  const requestOptions = {
    method: `GET`,
    headers: headers,
  };
  const response = await fetch(url, requestOptions);
  const body = await response.json();
  ResponseHandler(response, body);
  // return body
  if (body.isSuccess) {
    DataPredictionV2ResponseState.set(body.data);
  }
}

// update DataPredictionV2s
export async function updateDataPredictionV2(
  id: string,
  requestBody: DataPredictionV2Response
): Promise<void> {
  const url = endpoints.dataPredictionV2 + `${id}/`;
  const headers = {
    "Content-Type": `application/json`,
    Authorization: `Bearer ${localStorage.getItem(`accessToken`)}`,
    "x-timezone-region": Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  // const requestBody:DataPredictionV2RequestBody = DataPredictionV2CreateState.get()

  const requestOptions = {
    method: `PATCH`,
    headers: headers,
    body: JSON.stringify(requestBody),
  };
  const response = await fetch(url, requestOptions);
  const body = await response.json();
  ResponseHandler(response, body);
  if (body.isSuccess) {
    DataPredictionV2ResponseState.set(body.data);
  }
}

// delete DataPredictionV2s
export async function deleteDataPredictionV2(id: string): Promise<boolean> {
  const url = endpoints.dataPredictionV2 + `${id}/`;
  const headers = {
    "Content-Type": `application/json`,
    Authorization: `Bearer ${localStorage.getItem(`accessToken`)}`,
    "x-timezone-region": Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  const requestOptions = {
    method: `DELETE`,
    headers: headers,
  };
  const response = await fetch(url, requestOptions);
  if (response.status === 204) {
    toast.success(`Success`, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 1000,
    });
    return true;
  } else {
    const body = await response.json();
    ResponseHandler(response, body);
    return false;
  }
}

// change user to deactivate
export async function multiUpdate(
  requestBody: DataPredictionV2Response[],
  ids: string[],
  action: "add" | "remove" | "delete"
): Promise<void> {
  // common part
  const url = endpoints.dataPredictionV2 + `multiple-update/`;
  const headers = {
    "Content-Type": `application/json`,
    Authorization: `Bearer ${localStorage.getItem(`accessToken`)}`,
    "x-timezone-region": Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  // filter data
  // eslint-disable-next-line array-callback-return
  requestBody = requestBody.filter((data) => {
    if (ids.includes(data.id)) {
      return data;
    }
  });

  // take action based on action type
  if (action === "add") {
    const body = requestBody.map((data) => {
      return {
        id: data.id,
        status: "Add",
      };
    });
    const requestOptions = {
      method: `POST`,
      headers: headers,
      body: JSON.stringify(body),
    };
    const response = await fetch(url, requestOptions);
    const responseBody = await response.json();
    ResponseHandler(response, responseBody);
  } else if (action === "remove") {
    const body = requestBody.map((data) => {
      return {
        id: data.id,
        status: "Discard",
      };
    });
    const requestOptions = {
      method: `POST`,
      headers: headers,
      body: JSON.stringify(body),
    };
    const response = await fetch(url, requestOptions);
    const responseBody = await response.json();
    ResponseHandler(response, responseBody);
  } else if (action === "delete") {
    const url = endpoints.dataPredictionV2 + `multiple-delete/`;
    const body = requestBody.map((data) => {
      return {
        id: data.id,
      };
    });
    const requestOptions = {
      method: `POST`,
      headers: headers,
      body: JSON.stringify(body),
    };
    const response = await fetch(url, requestOptions);
    //   if (response.status === 204) {
    //     await listDataCollections();
    //   }
  }
}
