import {  CSSProperties } from 'react';
import { BeatLoader } from 'react-spinners';

export interface ILoaderProps {
}

const override: CSSProperties = {
    display: "flex",
    margin: "40vh auto",
    borderColor: "blue",
    justifyContent: "center",
    alignItems: "center"
    

};
  

export default function Loader (props: ILoaderProps) {

  return (
    <>
      <BeatLoader color='#366CBD' margin='8px' cssOverride={override} />
    </>
  );
}