/*
This file contains the api endpoints
*/
export const baseUrl = `https://api.sulzer.atomicloops.link`;

export const endpoints = {
  users: `${baseUrl}/users/`,
  register: `${baseUrl}/register/`,
  login: `${baseUrl}/admin-login/`,
  refresh: `${baseUrl}/login/refresh/`,
  logout: `${baseUrl}/logout/`,
  forgetPassword: `${baseUrl}/admin-reset-password/request/`,
  resetPassword: `${baseUrl}/admin-reset-password/confirm/`,
  dataCollection: `${baseUrl}/data-collection/`,
  dataPrediction: `${baseUrl}/data-prediction/`,
  dataPredictionV2: `${baseUrl}/data-prediction-v2/`,
  projects: `${baseUrl}/projects/`,
  parts: `${baseUrl}/parts/`,
  drawings: `${baseUrl}/drawings/`,
  reportGenerator: `${baseUrl}/report-generator/`,
  statistics: `${baseUrl}/stats/`,
};
