import React, {
  ChangeEvent,
  FormEvent,
  MouseEventHandler,
  useState,
} from "react";
import style from "./ForgotPassword.module.css";

import sulzerLogo from "../../assets/sulzerlogo.svg";
import AtomicImg from "../../assets/AtomicLogo.svg";
import { ForgotPasswordState } from "../../states/ForgotPassword";
import { useHookstate } from "@hookstate/core";
import { ForgotPasswordRequestBody } from "../../types/ForgotPassword";
import { forgotPassword } from "../../services/ForgotPassword";
import FormInput from "../../components/FormInput/FormInput";

import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";

type Props = {};

const ForgotPassword = (props: Props) => {
  const [showReset, setShowReset] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = async (
    e: FormEvent<HTMLFormElement> | MouseEventHandler<HTMLButtonElement> | any
  ) => {
    e.preventDefault();
    const success = await forgotPassword();
    if (success) {
      setShowReset(!showReset);
    }
  };
  const handleSubmitReturn = async (
    e: FormEvent<HTMLFormElement> | MouseEventHandler<HTMLButtonElement> | any
  ) => {
    e.preventDefault();

    navigate("/login");
  };
  // handle input
  const formData = useHookstate<ForgotPasswordRequestBody>(ForgotPasswordState);
  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    formData.set({
      ...formData.get(),
      [name]: value,
    });
  };
  return (
    <>
      <div className={style.mainBlock}>
        <img src={sulzerLogo} alt="" className={style.sulzerLogo} />
        <div className={style.middleBlock}>
          {!showReset ? (
            <>
              <div className={style.innerBlock}>
                <h1 className={style.header}>Reset Your Password</h1>
                <form className={style.form} onSubmit={handleSubmit}>
                  <FormInput
                    content={"Enter your Email*"}
                    type={"email"}
                    placeholder={"user@sulzer.com"}
                    name={`email`}
                    onChange={handleInput}
                  />
                  <Button
                    text={`Send password reset email`}
                    clickHandler={handleSubmit}
                  />
                </form>
              </div>
            </>
          ) : (
            <>
              <div className={style.innerBlock}>
                <h1 className={style.header}>Reset Your Password</h1>
                <form className={style.form} onSubmit={handleSubmitReturn}>
                  <p className={style.returnText}>
                    Check your email for a link to reset your password. If it
                    doesn’t appear within a few minutes, check your spam folder.
                  </p>
                  <Button
                    text={`Return to Login`}
                    clickHandler={handleSubmitReturn}
                  />
                </form>
              </div>
            </>
          )}
        </div>
        <div className={style.atomicLoops}>
          <p className={style.poweredByText}> Powered by</p>
          <img src={AtomicImg} alt="" className={style.atomicImg} />
        </div>
      </div>
    </>
  );
};
export default ForgotPassword;
