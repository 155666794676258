import { endpoints } from "../utils/config";
import { ResponseHandler } from "../utils/ResponseHandler";
import {
  DrawingsResponse,
  ApiDrawingsResponse,
  DrawingsRequestBody,
} from "../types/Drawings";
import {
  DrawingsResponseListState,
  DrawingsResponseState,
} from "../states/Drawings";
import { toast } from "react-toastify";

// list Drawings

export async function listDrawings(queryUrl?: string): Promise<void> {
  // let url =  endpoints.drawings;
  // if (queryUrl) {
  //   url = queryUrl
  // }
  const url = queryUrl ? queryUrl : endpoints.drawings;
  const headers = {
    "Content-Type": `application/json`,
    Authorization: `Bearer ${localStorage.getItem(`accessToken`)}`,
    "x-timezone-region": Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  const requestOptions = {
    method: `GET`,
    headers: headers,
  };
  const response = await fetch(url, requestOptions);
  const body = await response.json();
  ResponseHandler(response, body);
  DrawingsResponseListState.set(body.data);
  // return body;
}

// create Drawings
export async function createDrawings(
  requestBody: DrawingsRequestBody
): Promise<ApiDrawingsResponse> {
  const url = endpoints.drawings;
  const headers = {
    "Content-Type": `application/json`,
    Authorization: `Bearer ${localStorage.getItem(`accessToken`)}`,
    "x-timezone-region": Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  const requestOptions = {
    method: `POST`,
    headers: headers,
    body: JSON.stringify(requestBody),
  };
  const response = await fetch(url, requestOptions);
  const body = await response.json();
  ResponseHandler(response, body);
  return body;
}

// read Drawings
export async function getDrawings(id: string): Promise<void> {
  const url = endpoints.drawings + `${id}/`;
  const headers = {
    "Content-Type": `application/json`,
    Authorization: `Bearer ${localStorage.getItem(`accessToken`)}`,
    "x-timezone-region": Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  const requestOptions = {
    method: `GET`,
    headers: headers,
  };
  const response = await fetch(url, requestOptions);
  const body = await response.json();
  const success = ResponseHandler(response, body);
  // return body;
  if (success) {
    DrawingsResponseState.set(body.data);
  }
  // else{
  //   destroyDrawingsResponseState.set({})
  // }
}

// update Drawings
export async function updateDrawings(
  id: string,
  requestBody: DrawingsResponse
): Promise<void> {
  const url = endpoints.drawings + `${id}/`;
  const headers = {
    "Content-Type": `application/json`,
    Authorization: `Bearer ${localStorage.getItem(`accessToken`)}`,
    "x-timezone-region": Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  const requestOptions = {
    method: `PATCH`,
    headers: headers,
    body: JSON.stringify(requestBody),
  };
  const response = await fetch(url, requestOptions);
  const body = await response.json();
  const success = ResponseHandler(response, body);
  if (success) {
    DrawingsResponseState.set(body.data);
  }
}

// delete Drawings
export async function deleteDrawings(id: string): Promise<boolean> {
  const url = endpoints.drawings + `${id}/`;
  const headers = {
    "Content-Type": `application/json`,
    Authorization: `Bearer ${localStorage.getItem(`accessToken`)}`,
    "x-timezone-region": Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  const requestOptions = {
    method: `DELETE`,
    headers: headers,
  };
  const response = await fetch(url, requestOptions);
  if (response.status === 204) {
    toast.success(`Success`, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 1000,
    });
    return true;
  } else {
    const body = await response.json();
    ResponseHandler(response, body);
    return false;
  }
}

// multiple update/delete actions
export async function multiUpdate(
  requestBody: DrawingsResponse[],
  ids: string[],
  action: "delete"
): Promise<void> {
  const url = endpoints.drawings + `multiple-delete/`;
  const headers = {
    "Content-Type": `application/json`,
    Authorization: `Bearer ${localStorage.getItem(`accessToken`)}`,
    "x-timezone-region": Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  // filter data
  requestBody = requestBody.filter((data) => {
    if (ids.includes(data.id)) {
      return data;
    }
  });

  const body = requestBody.map((data) => {
    return {
      id: data.id,
    };
  });
  const requestOptions = {
    method: `POST`,
    headers: headers,
    body: JSON.stringify(body),
  };
  const response = await fetch(url, requestOptions);
}
