import { useHookstate } from "@hookstate/core";
import React, { ChangeEvent, useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DrawingsResponseState } from "../../../states/Drawings";
import {
  deleteDrawings,
  getDrawings,
  updateDrawings,
} from "../../../services/Drawings";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader/Loader";
import Header from "../../../components/Header/Header";
import styles from "./EditDrawings.module.css";

type Props = {};

const EditDrawings = (props: Props) => {
  // states
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [deleteText, setDeleteText] = useState("");
  const formData = useHookstate(DrawingsResponseState);

  // 4.B Input Handler for Forms
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    formData.set({
      ...formData.get(),
      [name]: value,
    });
  };

  //hooks to fetch data
  useLayoutEffect(() => {
    const getData = async () => {
      setLoading(true);
      await getDrawings(id || ``);
      setLoading(false);
    };
    getData();
  }, []);
  // handle delete
  const handleDelete = async () => {
    if (deleteText === "confirm") {
      const response = await deleteDrawings(id || ``);
      if (response) {
        navigate("/drawings");
      }
    } else {
      toast.error(`Please type confirm`, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    }
  };
  const handleSave = async () => {
    await updateDrawings(id || ``, formData.get());
  };

  if (loading) {
    return (
      <>
        <Loader />
      </>
    );
  }
  return (
    <>
      <Header title={`Drawings > ${formData.get().name}`} />
      <div className={styles.section}>
        <div className={styles.inputs}>
          <p className={styles.label}>Created By:</p>
          <p className={styles.form_inputs}>{formData.get().userName}</p>
        </div>
        <div className={styles.inputs}>
          <p className={styles.label}>Project Name</p>
          <p className={styles.form_inputs}>{formData.get().projectName}</p>
        </div>

        {/* <div className={styles.inputs}>
          <p className={styles.label}>Part Name</p>
          <p className={styles.form_inputs}>{formData.get().partName}</p>
        </div> */}
        <div className={styles.inputs}>
          <p className={styles.label}>Drawing Id</p>
          <input
            className={styles.form_input}
            type="text"
            name="drawingId"
            value={formData.get().number}
            onChange={handleInputChange}
          />
        </div>
        <div className={styles.inputs}>
          <p className={styles.label}>Drawing Name</p>
          <input
            className={styles.form_input}
            type="text"
            name="drawingName"
            value={formData.get().name}
            onChange={handleInputChange}
          />
        </div>
        {/* <div className={styles.inputs}>
          <p className={styles.label}>Drawing Name</p>
          <input
            className={styles.form_input}
            type="text"
            name="drawingDescription"
            value={formData.get().drawingDescription}
            onChange={handleInputChange}
          />
        </div> */}
        <div className={styles.inputs}>
          <p className={styles.label}>Created on:</p>
          <p className={styles.form_inputs}>
            {formData.get().createdAt.toString()}
          </p>
        </div>
        <div className={styles.buttons_group}>
          <button className={styles.button} onClick={handleSave}>
            Save
          </button>
          <button
            className={styles.button}
            onClick={() => setIsDelete(!isDelete)}
          >
            Delete
          </button>
        </div>
        {isDelete && (
          <div className={styles.delete_main}>
            <p className={styles.delete_p}>
              This action cannot be undone. Please type “confirm” to proceed.{" "}
            </p>
            <input
              type="text"
              placeholder="Type “confirm”"
              className={styles.delete_input}
              value={deleteText}
              onChange={(e) => setDeleteText(e.target.value)}
            />
            <button className={styles.delete_button} onClick={handleDelete}>
              Confirm delete
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default EditDrawings;
