import { hookstate } from "@hookstate/core";
import {
Statistics
} from "../types/Statistics";


const JSONstate =
{
    weldCount: {
        "Accept" :0,
        "Defect" : 0
    },
    defectCount: {
        "Crack": 0,
        "Lack of Fusion": 0,
        "Lack of Penetration": 0,
        "Surface Porosity": 0,
        "Undercut": 0,
        "Surface Finish": 0,
        "Other": 0,
    },
}
const initialState = 
{
    dataCollection:  JSONstate,
    dataPrediction: JSONstate,
    dataPredictionV2: JSONstate
}
export const StatisticsState = hookstate<Statistics>(initialState);



