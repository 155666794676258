import { useEffect, useState } from "react";

import Checkbox from "../../assets/Checkbox.svg";
import Loader from "../../components/Loader/Loader";

import { getStatistic } from "../../services/Statistics";

import style from "./Statistics.module.css";
import { useHookstate } from "@hookstate/core";
import { StatisticsState } from "../../states/Statistics";

export interface IStatisticsProps {}

export default function StatisticsView(props: IStatisticsProps) {
  // states
  const [loading, setLoading] = useState(false);

  const formData = useHookstate(StatisticsState);
  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      await getStatistic();
      setLoading(false);
      //   if (body.isSuccess) {
      //     setFormData(body.data);
      //   }
    };
    getData();
  }, []);

  // 4.F Return Component
  if (loading) {
    return (
      <>
        <Loader />
      </>
    );
  }

  // UI Component
  return (
    <>
      <div className={style.dashboard}>
        <h1 className={style.heading}>Statistics</h1>
        {/* <h1></h1> */}
        <h1>Data Collection</h1>
        <div className={style.dashboardGrid}>
          <div className={style.gridContent}>
            <div className={style.gridImgContainer}>
              <img src={Checkbox} alt="" />
            </div>
            <div className={style.gridInfo}>
              <div>Accept</div>
              <div id={style.gridCount}>
                {formData.get().dataCollection.weldCount.Accept || 0}
              </div>
            </div>
          </div>
          <div className={style.gridContent}>
            <div className={style.gridImgContainer}>
              <img src={Checkbox} alt="" />
            </div>
            <div className={style.gridInfo}>
              <div>Defect</div>
              <div id={style.gridCount}>
                {formData.get().dataCollection.weldCount.Defect || 0}
              </div>
            </div>
          </div>
          <div className={style.gridContent}>
            <div className={style.gridImgContainer}>
              <img src={Checkbox} alt="" />
            </div>
            <div className={style.gridInfo}>
              <div>Crack</div>
              <div id={style.gridCount}>
                {formData.get().dataCollection.defectCount.Crack || 0}
              </div>
            </div>
          </div>
          <div className={style.gridContent}>
            <div className={style.gridImgContainer}>
              <img src={Checkbox} alt="" />
            </div>
            <div className={style.gridInfo}>
              <div>Lack of Fusion</div>
              <div id={style.gridCount}>
                {formData.get().dataCollection.defectCount["Lack of Fusion"] ||
                  0}
              </div>
            </div>
          </div>
          <div className={style.gridContent}>
            <div className={style.gridImgContainer}>
              <img src={Checkbox} alt="" />
            </div>
            <div className={style.gridInfo}>
              <div>Lack of Penetration</div>
              <div id={style.gridCount}>
                {formData.get().dataCollection.defectCount[
                  "Lack of Penetration"
                ] || 0}
              </div>
            </div>
          </div>
          <div className={style.gridContent}>
            <div className={style.gridImgContainer}>
              <img src={Checkbox} alt="" />
            </div>
            <div className={style.gridInfo}>
              <div>Surface Porosity</div>
              <div id={style.gridCount}>
                {formData.get().dataCollection.defectCount[
                  "Surface Porosity"
                ] || 0}
              </div>
            </div>
          </div>
          <div className={style.gridContent}>
            <div className={style.gridImgContainer}>
              <img src={Checkbox} alt="" />
            </div>
            <div className={style.gridInfo}>
              <div>Undercut</div>
              <div id={style.gridCount}>
                {formData.get().dataCollection.defectCount.Undercut || 0}
              </div>
            </div>
          </div>
          <div className={style.gridContent}>
            <div className={style.gridImgContainer}>
              <img src={Checkbox} alt="" />
            </div>
            <div className={style.gridInfo}>
              <div>Surface Finish</div>
              <div id={style.gridCount}>
                {formData.get().dataCollection.defectCount["Surface Finish"] ||
                  0}
              </div>
            </div>
          </div>
          <div className={style.gridContent}>
            <div className={style.gridImgContainer}>
              <img src={Checkbox} alt="" />
            </div>
            <div className={style.gridInfo}>
              <div>Other</div>
              <div id={style.gridCount}>
                {formData.get().dataCollection.defectCount.Other || 0}
              </div>
            </div>
          </div>
        </div>
        <h1>Data Prediction</h1>
        <div className={style.dashboardGrid}>
          <div className={style.gridContent}>
            <div className={style.gridImgContainer}>
              <img src={Checkbox} alt="" />
            </div>
            <div className={style.gridInfo}>
              <div>Accept</div>
              <div id={style.gridCount}>
                {formData.get().dataPrediction.weldCount.Accept || 0}
              </div>
            </div>
          </div>
          <div className={style.gridContent}>
            <div className={style.gridImgContainer}>
              <img src={Checkbox} alt="" />
            </div>
            <div className={style.gridInfo}>
              <div>Defect</div>
              <div id={style.gridCount}>
                {formData.get().dataPrediction.weldCount.Defect || 0}
              </div>
            </div>
          </div>
          <div className={style.gridContent}>
            <div className={style.gridImgContainer}>
              <img src={Checkbox} alt="" />
            </div>
            <div className={style.gridInfo}>
              <div>Crack</div>
              <div id={style.gridCount}>
                {formData.get().dataPrediction.defectCount.Crack || 0}
              </div>
            </div>
          </div>
          <div className={style.gridContent}>
            <div className={style.gridImgContainer}>
              <img src={Checkbox} alt="" />
            </div>
            <div className={style.gridInfo}>
              <div>Lack of Fusion</div>
              <div id={style.gridCount}>
                {formData.get().dataPrediction.defectCount["Lack of Fusion"] ||
                  0}
              </div>
            </div>
          </div>
          <div className={style.gridContent}>
            <div className={style.gridImgContainer}>
              <img src={Checkbox} alt="" />
            </div>
            <div className={style.gridInfo}>
              <div>Lack of Penetration</div>
              <div id={style.gridCount}>
                {formData.get().dataPrediction.defectCount[
                  "Lack of Penetration"
                ] || 0}
              </div>
            </div>
          </div>
          <div className={style.gridContent}>
            <div className={style.gridImgContainer}>
              <img src={Checkbox} alt="" />
            </div>
            <div className={style.gridInfo}>
              <div>Surface Porosity</div>
              <div id={style.gridCount}>
                {formData.get().dataPrediction.defectCount[
                  "Surface Porosity"
                ] || 0}
              </div>
            </div>
          </div>
          <div className={style.gridContent}>
            <div className={style.gridImgContainer}>
              <img src={Checkbox} alt="" />
            </div>
            <div className={style.gridInfo}>
              <div>Undercut</div>
              <div id={style.gridCount}>
                {formData.get().dataPrediction.defectCount.Undercut || 0}
              </div>
            </div>
          </div>
          <div className={style.gridContent}>
            <div className={style.gridImgContainer}>
              <img src={Checkbox} alt="" />
            </div>
            <div className={style.gridInfo}>
              <div>Surface Finish</div>
              <div id={style.gridCount}>
                {formData.get().dataPrediction.defectCount["Surface Finish"] ||
                  0}
              </div>
            </div>
          </div>
          <div className={style.gridContent}>
            <div className={style.gridImgContainer}>
              <img src={Checkbox} alt="" />
            </div>
            <div className={style.gridInfo}>
              <div>Other</div>
              <div id={style.gridCount}>
                {formData.get().dataPrediction.defectCount.Other || 0}
              </div>
            </div>
          </div>
        </div>

        <h1>Data Prediction V2</h1>
        <div className={style.dashboardGrid}>
          <div className={style.gridContent}>
            <div className={style.gridImgContainer}>
              <img src={Checkbox} alt="" />
            </div>
            <div className={style.gridInfo}>
              <div>Accept</div>
              <div id={style.gridCount}>
                {formData.get().dataPredictionV2.weldCount.Accept || 0}
              </div>
            </div>
          </div>
          <div className={style.gridContent}>
            <div className={style.gridImgContainer}>
              <img src={Checkbox} alt="" />
            </div>
            <div className={style.gridInfo}>
              <div>Defect</div>
              <div id={style.gridCount}>
                {formData.get().dataPredictionV2.weldCount.Defect || 0}
              </div>
            </div>
          </div>
          <div className={style.gridContent}>
            <div className={style.gridImgContainer}>
              <img src={Checkbox} alt="" />
            </div>
            <div className={style.gridInfo}>
              <div>Crack</div>
              <div id={style.gridCount}>
                {formData.get().dataPredictionV2.defectCount.Crack || 0}
              </div>
            </div>
          </div>
          <div className={style.gridContent}>
            <div className={style.gridImgContainer}>
              <img src={Checkbox} alt="" />
            </div>
            <div className={style.gridInfo}>
              <div>Lack of Fusion</div>
              <div id={style.gridCount}>
                {formData.get().dataPredictionV2.defectCount[
                  "Lack of Fusion"
                ] || 0}
              </div>
            </div>
          </div>
          <div className={style.gridContent}>
            <div className={style.gridImgContainer}>
              <img src={Checkbox} alt="" />
            </div>
            <div className={style.gridInfo}>
              <div>Lack of Penetration</div>
              <div id={style.gridCount}>
                {formData.get().dataPredictionV2.defectCount[
                  "Lack of Penetration"
                ] || 0}
              </div>
            </div>
          </div>
          <div className={style.gridContent}>
            <div className={style.gridImgContainer}>
              <img src={Checkbox} alt="" />
            </div>
            <div className={style.gridInfo}>
              <div>Surface Porosity</div>
              <div id={style.gridCount}>
                {formData.get().dataPredictionV2.defectCount[
                  "Surface Porosity"
                ] || 0}
              </div>
            </div>
          </div>
          <div className={style.gridContent}>
            <div className={style.gridImgContainer}>
              <img src={Checkbox} alt="" />
            </div>
            <div className={style.gridInfo}>
              <div>Undercut</div>
              <div id={style.gridCount}>
                {formData.get().dataPredictionV2.defectCount.Undercut || 0}
              </div>
            </div>
          </div>
          <div className={style.gridContent}>
            <div className={style.gridImgContainer}>
              <img src={Checkbox} alt="" />
            </div>
            <div className={style.gridInfo}>
              <div>Surface Finish</div>
              <div id={style.gridCount}>
                {formData.get().dataPredictionV2.defectCount[
                  "Surface Finish"
                ] || 0}
              </div>
            </div>
          </div>
          <div className={style.gridContent}>
            <div className={style.gridImgContainer}>
              <img src={Checkbox} alt="" />
            </div>
            <div className={style.gridInfo}>
              <div>Other</div>
              <div id={style.gridCount}>
                {formData.get().dataPredictionV2.defectCount.Other || 0}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
