import React, {
  ChangeEvent,
  FormEvent,
  MouseEventHandler,
  useState,
} from "react";
import style from "../../styles/Login.module.css";
import sulzerLogo from "../../assets/sulzerlogo.svg";
import AtomicImg from "../../assets/AtomicLogo.svg";
import { LoginState } from "../../states/Login";
import { useHookstate } from "@hookstate/core";
import { LoginRequestBody } from "../../types/Login";
import { login } from "../../services/Login";
import FormInput from "../../components/FormInput/FormInput";
import PasswordInput from "../../components/PasswordInput/PasswordInput";
import AdminPanel from "../../assets/Admin Panel.svg";
import Button from "../../components/Button/Button";
import { Link, useNavigate } from "react-router-dom";

type Props = {};

const Login = (props: Props) => {
  // handle login
  const navigate = useNavigate();
  const handleSubmit = async (
    e: FormEvent<HTMLFormElement> | MouseEventHandler<HTMLButtonElement> | any
  ) => {
    e.preventDefault();
    const success = await login();
    if (success) {
      navigate("/data-collection");
    }
  };
  // handle input
  const formData = useHookstate<LoginRequestBody>(LoginState);
  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    formData.set({
      ...formData.get(),
      [name]: value,
    });
  };
  const [eyeClose, setEyeClose] = useState(false);
  return (
    <>
      <div className={style.wrapper}>
        <div className={style.leftSide}>
          <div>
            <img className={style.sulzerLogo} src={sulzerLogo} alt="" />

            <h1 className={style.header}>Admin Panel</h1>
            <p className={style.subHeader}>Log in Here</p>
          </div>
          <div>
            <form className={style.form} onSubmit={handleSubmit}>
              <FormInput
                content={"Enter Email Address*"}
                type={"email"}
                placeholder={"user@sulzer.com"}
                name={`email`}
                onChange={handleInput}
              />

              <PasswordInput
                content={"Enter Password*"}
                type={eyeClose ? "text" : "password"}
                placeholder={"password"}
                name={`password`}
                onChange={handleInput}
                setEyeClose={setEyeClose}
                eyeClose={eyeClose}
              />
              <br />

              <Button text={`Log in`} clickHandler={handleSubmit} />
              <br />
              <br />
              <Link to="/forgot-password" className={style.links}>
                Forgot Password?
              </Link>
            </form>
          </div>
          <div className={style.atomicLoops}>
            <p className={style.poweredByText}> Powered by</p>
            <img src={AtomicImg} alt="" className={style.atomicImg} />
          </div>
        </div>
        <img className={style.adminImage} src={AdminPanel} alt="" />
      </div>
    </>
  );
};

export default Login;
