import { hookstate } from "@hookstate/core";
import {
  DrawingsRequestBody,
  DrawingsResponse,
  ListData,
} from "../types/Drawings";

export const DrawingCreateState = hookstate<DrawingsRequestBody>({
  number: "",
  name: "",
  userId: "",
  projectId: "",
  partId: "",
});

const initailState = {
  number: "",
  name: "",
  userId: "",
  projectId: "",
  partId: "",
  id: "",
  partName: "",
  projectName: "",
  userName: "",
  createdAt: "",
};

export const DrawingsResponseState = hookstate<DrawingsResponse>(initailState);

export const DrawingsResponseListState = hookstate<ListData>({
  count: 0,
  next: null,
  previous: null,
  currentPage: 0,
  totalPages: 0,
  results: [initailState],
});
