import React, { useEffect, useState } from "react";
import Header from "../../../components/Header/Header";
import style from "./ListDataCollection.module.css";
import PrevButton from "../../../assets/greater.svg";
import NextButton from "../../../assets/lesser.svg";

import cancelCross from "../../../assets/cancelCross.svg";
import emptyImg from "../../../assets/empty.svg";

// import cancelCross from "../../assets/cancelCross.svg";

import { useHookstate } from "@hookstate/core";
import { DataCollectionResponseListState } from "../../../states/DataCollection";
import {
  listDataCollections,
  multiUpdate,
} from "../../../services/DataCollection";
import { DataCollectionResponse } from "../../../types/DataCollection";
import { SearchFilterUrlHandler } from "../../../utils/searchFilterHandler";
import { endpoints } from "../../../utils/config";
import { useNavigate } from "react-router-dom";
type Props = {};

const ListDataCollection = (props: Props) => {
  const options = [
    { label: "Crack", value: 0 },
    { label: "Lack of fusion", value: 1 },
    { label: "Surface Porosity", value: 2 },
    { label: "Lack of Penetration", value: 3 },
    { label: "Undercut", value: 4 },
    { label: "Surface Finish", value: 5 },
    { label: "other", value: 6 },
  ];
  const navigate = useNavigate();
  // Table States
  const listData = useHookstate(DataCollectionResponseListState);
  const [dataId, setDataId] = useState<Array<any>>([]);
  const [checkbox, setCheckbox] = useState(false);

  // search states
  const [textSearch, setTextSearch] = useState("");

  const [search, setSearch] = useState(false);

  // filter states
  const [filterFromDate, setFilterFromDate] = useState(``);
  const [filterToDate, setFilterToDate] = useState(``);
  const [weldType, setWeldType] = useState(``);
  const [defectType, setDefectType] = useState<Array<string>>([]);
  const [filter, setFilter] = useState(false);
  const [status, setStatus] = useState(``);
  //other states

  // check box logic
  const handleCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = e.target.checked;
    setCheckbox(!checkbox);
    if (e.target.checked) {
      const data = listData.get().results.map((data) => data.id);

      setDataId(data);
    } else {
      setDataId([]);
    }
  };
  const chooseCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = e.target.value;
    const check = e.target.checked;
    if (dataId.includes(id)) {
      const idCollection = dataId.filter((item) => item !== id);
      setDataId(idCollection);
      setCheckbox(false);
    } else {
      const idCollection = [...dataId];
      idCollection.push(id);
      setDataId((prev) => {
        return [...prev, id];
      });

      if (idCollection.length === listData.results.length) {
        setCheckbox(true);
      } else {
        setCheckbox(false);
      }
    }
  };

  // handle action
  const handleAction = async (e: any) => {
    e.preventDefault();

    let requestBody = listData
      .get()
      .results.map((data: DataCollectionResponse) =>
        JSON.parse(JSON.stringify(data))
      );
    await multiUpdate(requestBody, dataId, e.target.value);
    setCheckbox(false);
    await listDataCollections();
    setDataId([]);
    // window.location.reload()
  };

  //
  const handlePreviousPageButton = async (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    if (listData.get().previous) {
      await listDataCollections(listData.get().previous as string);
    }
  };

  const handleNextPageButton = async (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    if (listData.get().next) {
      await listDataCollections(listData.get().next as string);
    }
  };

  const handleSidebarFilter = async (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    setFilter(true);
    const searchFilterParams = {
      search: textSearch,
      fromDate: filterFromDate,
      toDate: filterToDate,
      typeOfWeld: weldType,
      typeOfDefect: defectType,
      status: status,
    };
    const queryUrl = SearchFilterUrlHandler(
      endpoints.dataCollection,
      searchFilterParams
    );
    await listDataCollections(queryUrl);
  };
  const handleFilterClear = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setFilter(false);
    setFilterFromDate(``);
    setFilterToDate(``);
    setWeldType(``);
    setDefectType([]);
    setStatus(``);
    const searchFilterParams = {
      search: textSearch,
    };
    const queryUrl = SearchFilterUrlHandler(
      endpoints.dataCollection,
      searchFilterParams
    );
    await listDataCollections(queryUrl);
  };

  const clearSearch = async (e: React.MouseEvent<HTMLImageElement>) => {
    e.preventDefault();
    setTextSearch((prev) => "");
    setSearch(false);
    const searchFilterParams = {
      fromDate: filterFromDate,
      toDate: filterToDate,
      typeOfWeld: weldType,
      typeOfDefect: defectType,
      status: status,
    };
    const queryUrl = SearchFilterUrlHandler(
      endpoints.dataCollection,
      searchFilterParams
    );
    await listDataCollections(queryUrl);
  };

  const handleDefectType = (e: React.ChangeEvent<HTMLInputElement>) => {
    const id = e.target.name;
    if (defectType.includes(id)) {
      const idCollection = defectType.filter((item) => item !== id);
      setDefectType(idCollection);
    } else {
      const idCollection = [...defectType];
      idCollection.push(id);
      setDefectType(idCollection);
    }
  };

  const handleSearch = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setSearch(true);
    const searchFilterParams = {
      search: textSearch,
      fromDate: filterFromDate,
      toDate: filterToDate,
      typeOfWeld: weldType,
      typeOfDefect: defectType,
      status: status,
    };
    const queryUrl = SearchFilterUrlHandler(
      endpoints.dataCollection,
      searchFilterParams
    );
    await listDataCollections(queryUrl);
  };

  const clearResults = async (e: React.MouseEvent<HTMLButtonElement>) => {
    setSearch(false);
    setFilter(false);
    setTextSearch(``);
    setFilterFromDate(``);
    setFilterToDate(``);
    setWeldType(``);
    setDefectType([]);
    setStatus(``);
  };
  // fetch api
  useEffect(() => {
    const getData = async () => {
      await listDataCollections();
    };
    getData();
  }, []);
  return (
    <>
      {/* header */}
      <Header title={"Data Collection"} />
      {/* main Container */}
      <div className={style.container}>
        <div className={style.searchBarAndTable}>
          {/* SearchBar */}
          <div className={style.searchBar}>
            {/* Action Section */}
            <select className={style.selectAction} onChange={handleAction}>
              <option>Action</option>
              <option value="add">Add to training Data</option>
              <option value="remove">Remove from training data</option>
              <option value="delete">Delete</option>
            </select>

            <form>
              <div className={style.inputBox}>
                <input
                  type="text"
                  placeholder="Search by Name"
                  value={textSearch}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setTextSearch(e.target.value);
                  }}
                  className={style.searchInput}
                />
                {textSearch !== "" && (
                  <img
                    src={cancelCross}
                    alt=""
                    className={style.cancelSearch}
                    onClick={clearSearch}
                  />
                )}

                <button
                  className={style.searchBtn}
                  type="submit"
                  onClick={handleSearch}
                >
                  Search
                </button>
              </div>
            </form>
          </div>
          {/* table */}
          {(search || filter) && (
            <div className={style.userClearBox}>
              <p className={style.userResult}>Results</p>
              <button className={style.userResultBtn} onClick={clearResults}>
                Clear results
              </button>
            </div>
          )}
          <div className={style.tableBox}>
            {listData && listData.get().results.length > 0 ? (
              <>
                <table className={style.tables}>
                  <thead className={style.tableHead}>
                    <tr>
                      <td>
                        <input
                          type="checkbox"
                          className={style.checkBox}
                          checked={checkbox}
                          onChange={handleCheckbox}
                        />
                      </td>
                      <td className={style.tableHeadData}>Image preview</td>
                      <td className={style.tableHeadData}>User name</td>
                      <td className={style.tableHeadData}>Weld type</td>
                      <td className={style.tableHeadData}>Defect type</td>
                    </tr>
                  </thead>
                  <tbody>
                    {listData.get().results.map((item: any, index: number) => {
                      return (
                        <tr key={index} className={style.tableRow}>
                          <td>
                            <input
                              type="checkbox"
                              className={style.checkBox}
                              value={item.id}
                              name={item.id}
                              // defaultChecked={dataId.includes(item.id)}
                              checked={dataId.includes(item.id)}
                              onChange={chooseCheckbox}
                            />
                          </td>
                          <td>
                            {" "}
                            <img
                              src={item.imageUrl}
                              alt=""
                              // className={style.tableEditData}
                              onClick={() =>
                                navigate(`/data-collection/edit/${item.id}}`)
                              }
                              className={style.tableImg}
                            />
                          </td>
                          <td className={style.tableRowData}>
                            {item.userName}
                          </td>
                          <td className={style.tableRowData}>
                            {item.typeOfWeld}
                          </td>
                          <td className={style.tableRowData}>
                            {item.typeOfDefect || `----`}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </>
            ) : (
              <>
                <div className={style.elseTableBlock}>
                  <img src={emptyImg} alt="" className={style.emptyImg} />
                  <h3 className={style.emptyText}>No data available!</h3>
                </div>
              </>
            )}
          </div>
          {/* pagination */}
          <div className={style.paginationBar}>
            <div className={style.pagination}>
              <button
                disabled={listData.get().previous ? false : true}
                onClick={handlePreviousPageButton}
                // onClick={async () => {
                //   await listDataCollections(listData.get().previous as string);
                // }}
                className={style.prevBtn}
              >
                <img src={PrevButton} alt="" />
              </button>
              {/* <img src={greater} alt="" className={style.paginationImg} /> */}
              {listData.get().currentPage} to {listData.get().totalPages}
              {/* <img src={lesser} alt="" className={style.paginationImg} /> */}
              <button
                disabled={listData.get().next ? false : true}
                onClick={handleNextPageButton}
                // onClick={async (e:any) => {
                //   e.preventDefault();
                //   await listDataCollections(listData.get().next as string);
                //   window.location.reload()
                // }}
                className={style.prevBtn}
              >
                <img src={NextButton} alt="" />
              </button>
            </div>
          </div>
        </div>
        {/* sidebar filter */}
        <div className={style.sideFilter}>
          <div className={style.filterHead}>
            <button className={style.filterLeftP} onClick={handleSidebarFilter}>
              Apply Filters
            </button>
            <button className={style.filterRightP} onClick={handleFilterClear}>
              Clear all
            </button>
          </div>
          <p className={style.filtersText}>Filters</p>
          <h6 className={style.filterCreate}>Select Range</h6>

          <p className={style.toText}>from</p>
          <input
            value={filterFromDate}
            type="date"
            className={style.startDate}
            name="start_date"
            onChange={(e: any) => setFilterFromDate(e.target.value)}
          />
          <p className={style.toText}>to</p>
          <input
            type="date"
            className={style.startDate}
            value={filterToDate}
            onChange={(e: any) => setFilterToDate(e.target.value)}
            name="end_date"
          />
          {/* status type */}
          <h6 className={style.filterCreate}>Status</h6>
          <div className={style.levelWrapper}>
            {[
              {
                label: "Pending",
                value: "PENDING",
              },
              {
                label: "Add",
                value: "ADD",
              },
              {
                label: "Discard",
                value: "DISCARD",
              },
            ].map(({ label, value }) => {
              return (
                <label key={value} className={style.radioSqrBtn2}>
                  <input
                    type="radio"
                    value={status}
                    name="radio-btn"
                    onChange={() => setStatus(value)}
                    className={style.radioBtn}
                  />
                  <span className={style.radioBtnText}>{label}</span>
                </label>
              );
            })}
          </div>
          {/* Weld type */}
          <h6 className={style.filterCreate}>Weld Type</h6>
          <div className={style.levelWrapper}>
            {[
              {
                label: "Accept",
                value: "Accept",
              },
              {
                label: "Defect",
                value: "Defect",
              },
            ].map(({ label, value }) => {
              return (
                <label key={label} className={style.radioSqrBtn2}>
                  <input
                    type="radio"
                    value={weldType}
                    name="Active"
                    onChange={() => setWeldType(value)}
                    className={style.radioBtn}
                  />
                  <span className={style.radioBtnText}>{label}</span>
                </label>
              );
            })}
          </div>

          {/* defect type */}
          <h6 className={style.filterCreate}>Defect Type</h6>
          <div className={style.levelWrapper}>
            {options.map(({ label, value }) => {
              return (
                <label key={value} className={style.radioSqrBtn2}>
                  <input
                    type="checkbox"
                    value={value}
                    name={label}
                    onChange={handleDefectType}
                    className={style.radioBtn}
                  />
                  <span className={style.radioBtnText}>{label}</span>
                </label>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ListDataCollection;
