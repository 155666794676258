import React, {
  ChangeEvent,
  FormEvent,
  MouseEventHandler,
  useEffect,
  useState,
} from "react";
import style from "./ResetPassword.module.css";

import sulzerLogo from "../../assets/sulzerlogo.svg";
import AtomicImg from "../../assets/AtomicLogo.svg";
import { ResetPasswordState } from "../../states/ResetPassword";
import { useHookstate } from "@hookstate/core";
import { ResetPasswordForm } from "../../types/ResetPassword";

import FormInput from "../../components/FormInput/FormInput";

import Button from "../../components/Button/Button";
import PasswordInput from "../../components/PasswordInput/PasswordInput";
import { useNavigate } from "react-router-dom";
import { resetPassword } from "../../services/ResetPassword";

import { useSearchParams } from "react-router-dom";

type Props = {};

const ResetPassword = (props: Props) => {
  const [eyeClose, setEyeClose] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const token = Number(searchParams.get("token"));
  const formData = useHookstate<ResetPasswordForm>(ResetPasswordState);
  useEffect(() => {
    formData.set({
      ...formData.get(),
      [`token`]: token,
    });
  }, [])
  const navigate = useNavigate();
  const handleSubmit = async (
    e: FormEvent<HTMLFormElement> | MouseEventHandler<HTMLButtonElement> | any
  ) => {
    e.preventDefault();
    const success = await resetPassword();
    if (success) {
      return navigate("/login");
    }
  };

  // handle input
  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    formData.set({
      ...formData.get(),

      [name]: value,
    });
  };
  return (
    <>
      <div className={style.mainBlock}>
        <img src={sulzerLogo} alt="" className={style.sulzerLogo} />
        <div className={style.middleBlock}>
          <div className={style.innerBlock}>
            <h1 className={style.header}>Reset Password</h1>
            <form className={style.form} onSubmit={handleSubmit}>
              <FormInput
                content={"New Password*"}
                type={"password"}
                placeholder={"********"}
                name={`password`}
                onChange={handleInput}
              />
              <PasswordInput
                content={"Confirm Password*"}
                type={eyeClose ? "text" : "password"}
                placeholder={"password"}
                name={`newPassword`}
                onChange={handleInput}
                setEyeClose={setEyeClose}
                eyeClose={eyeClose}
              />
              <label className={style.formLabel}>OTP</label>
              <input
                className={style.otpInput}
                name={`token`}
                value={token}
                readOnly
              />
              <Button text={`Save new password`} clickHandler={handleSubmit} />
            </form>
          </div>
        </div>
        <div className={style.atomicLoops}>
          <p className={style.poweredByText}> Powered by</p>
          <img src={AtomicImg} alt="" className={style.atomicImg} />
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
