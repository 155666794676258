import { endpoints } from "../utils/config";
import { ResponseHandler } from "../utils/ResponseHandler";
// import {}
import { LoginRequestBody, JWTDecode } from "../types/Login";
import { LoginState } from "../states/Login";

import { getUsers } from "./Users";
import jwtDecode from "jwt-decode";
// const navigate = useNavigate()

//list parts

// create part
export async function login(): Promise<boolean> {
  // const n = useNavigate()
  const requestBody: LoginRequestBody = LoginState.get();
  const url = endpoints.login;
  const headers = {
    "Content-Type": `application/json`,
    "x-timezone-region": Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  const requestOptions = {
    method: `POST`,
    headers: headers,
    body: JSON.stringify(requestBody),
  };
  const response = await fetch(url, requestOptions);
  const body = await response.json();
  ResponseHandler(response, body);
  if (body.isSuccess) {
    LoginState.set(body.data);
  }
  if (body.isSuccess) {
    var decoded: JWTDecode = jwtDecode(body.data.access);
    const userId = decoded.user_id;
    localStorage.setItem("refreshToken", body.data.refresh);
    localStorage.setItem("accessToken", body.data.access);
    localStorage.setItem("userId", userId);
    const success = await getUsers(userId);
    if (success) {
      return true;
    }
    return false;
  }
  return false;
}
