import { endpoints } from "../utils/config";
import { ResponseHandler } from "../utils/ResponseHandler";
// import {}
import { ForgotPasswordRequestBody } from "../types/ForgotPassword";
import { ForgotPasswordState } from "../states/ForgotPassword";

// const navigate = useNavigate()

//list parts

// create part
export async function forgotPassword(): Promise<boolean> {
  // const n = useNavigate()
  const requestBody: ForgotPasswordRequestBody = ForgotPasswordState.get();
  const url = endpoints.forgetPassword;
  const headers = {
    "Content-Type": `application/json`,
    "x-timezone-region": Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  const requestOptions = {
    method: `POST`,
    headers: headers,
    body: JSON.stringify(requestBody),
  };
  const response = await fetch(url, requestOptions);
  const body = await response.json();
  ResponseHandler(response, body);
  if (body.isSuccess) {
    ForgotPasswordState.set(body.data);
  }
  if (body.isSuccess) {
    return true;
  }
  return false;
}
