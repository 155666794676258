import { useHookstate } from "@hookstate/core";
import React, {  useLayoutEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DataPredictionResponseState } from "../../../states/DataPrediction";
import {
  deleteDataPrediction,
  getDataPrediction,
  updateDataPrediction,
} from "../../../services/DataPrediction";
import { toast } from "react-toastify";
import style from "./EditDataPrediction.module.css";

type Props = {};

const EditDataPrediction = (props: Props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const formdata = useHookstate(DataPredictionResponseState);

  const [deleteText, setDeleteText] = useState();
  const [isdelete, setIsDelete] = useState(false);
  const defectTypeRef = useRef<HTMLSelectElement>(null);
  const userPredictionRef = useRef<HTMLSelectElement>(null);

  const [userPredictionIsDefect, setUserPredictionIsDefect] = useState(false);

  useLayoutEffect(() => {
    const getData = async () => {
      await getDataPrediction(id || ``);
      userPredictionRef.current!.value = formdata.get().adminOnePrediction;
      setUserPredictionIsDefect(
        formdata.get().adminOnePrediction === "Defect" ? true : false
      );
    };
    getData();
  }, []);

  const handleDelete = async () => {
    if (deleteText === "confirm") {
      const response = await deleteDataPrediction(id || ``);
      if (response) {
        navigate("/drawings");
      }
    } else {
      toast.error(`Please type confirm`, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 1000,
      });
    }
  };
  const handleSave = async () => {
    await updateDataPrediction(id || ``, formdata.get());
  };
  // Handler input
  // // 4.B Input Handler for Forms
  // const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = event.target;
  // };
  return (
    <>
      <div className={style.container}>
        <div className={style.form_section}>
          <div className={style.image_section}>
            <img
              src={formdata.get().imageUrl}
              alt=""
              className={style.update_img}
            />
          </div>
          <div className={style.form_data}>
            <div className={style.inputs}>
              <p className={style.label}>User name</p>
              <p className={style.updatedelete_form_inputs}>
                {formdata.userName.get()}
              </p>
            </div>

            <div className={style.inputs}>
              <p className={style.label}>Model Prediction for Weld:</p>
              <p className={style.updatedelete_form_inputs}>
                {formdata.modelOnePrediction.get()}
              </p>
            </div>
            <div className={style.inputs}>
              <p className={style.label}>Model Prediction for Defect Type:</p>
              <p className={style.updatedelete_form_inputs}>
                {formdata.modelTwoPrediction.get() || `-----`}
              </p>
            </div>

            <div className={style.inputs}>
              <p className={style.label}>User Prediction for Weld Type:</p>
              <p className={style.updatedelete_form_inputs}>
                {formdata.actualOnePrediction.get()}
              </p>
            </div>

            <div className={style.inputs}>
              <p className={style.label}>User Prediction for Defect Type:</p>
              <p className={style.updatedelete_form_inputs}>
                {formdata.actualTwoPrediction.get() || `-----`}
              </p>
            </div>
            <div className={style.inputs}>
              <p className={style.label}>Admin Prediction Defect Type: </p>
              {/* <p className={style.inputField}>{formData.actualOnePrediction}</p> */}
              <select
                ref={userPredictionRef}
                onChange={(e) => {
                  if (e.target.value === "Defect") {
                    formdata.set({
                      ...formdata.get(),
                      adminOnePrediction: "Defect",
                    });
                    setUserPredictionIsDefect(true);
                  } else {
                    formdata.set({
                      ...formdata.get(),
                      adminOnePrediction: "Accept",
                    });
                    setUserPredictionIsDefect(false);
                  }
                }}
                name="adminOnePrediction"
                id="adminOnePrediction"
                className={style.selectInput}
              >
                <option>----</option>
                <option value="Accept">Accept</option>
                <option value="Defect">Defect</option>
              </select>
            </div>
            {userPredictionIsDefect && (
              <>
                <div className={style.inputs}>
                  <p className={style.label}>Defect Type: </p>
                  <select
                    onChange={(e) => {
                      formdata.set({
                        ...formdata.get(),
                        adminTwoPrediction: e.target.value,
                      });
                    }}
                    ref={defectTypeRef}
                    defaultValue={formdata.adminTwoPrediction.get()}
                    className={style.selectInput}
                  >
                    <option>----</option>
                    <option defaultChecked>Select type of defect</option>
                    <option>Crack</option>
                    <option>Lack of Fusion</option>
                    <option>Lack of Penetration</option>
                    <option>Surface Porosity</option>
                    <option>Undercut</option>
                    <option>Surface Finish</option>
                    <option>other</option>
                  </select>
                </div>
              </>
            )}
            <div className={style.inputs}>
              <p className={style.label}>Created on:</p>
              <p className={style.updatedelete_form_inputs}>
                {formdata.createdAt.get().toString()}
              </p>
            </div>

            <div className={style.update_btnbox}>
              <button className={style.upadte_save_btn} onClick={handleSave}>
                Save
              </button>
              <button
                className={style.upadte_save_btn}
                onClick={() => setIsDelete(!isdelete)}
              >
                Delete
              </button>
            </div>

            {isdelete && (
              <div className={style.delete_main}>
                <p className={style.delete_p}>
                  This action cannot be undone. Please type “confirm” to
                  proceed.{" "}
                </p>
                <input
                  type="text"
                  placeholder="Type “confirm”"
                  className={style.delete_input}
                  value={deleteText}
                  onChange={(e: any) => setDeleteText(e.target.value)}
                />
                <button className={style.delete_btn} onClick={handleDelete}>
                  Confirm delete
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditDataPrediction;
